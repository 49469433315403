.nsx-form-phone-array {
  $icon-width: 25px; // this is set on the button so this variable isn't actually used
  @extend .clearfix;
  position: relative;
  margin-top: $nsx-form-small-gutter;

  .nsx-form-group__input {
    width: 100%;
  }

  .nsx-form-group__main-col {
    width: 40%;
  }

  .nsx-form-group__secondary-col {
    width: 60%;
  }

  .nsx-form-group__errors {
    display: block !important;
    position: static !important;
    margin-top: 2px !important;
  }

  &:first-child {
    margin-top: 0;
  }

  &__remove-btn {
    position: absolute;
    right: calc(-1 * ($nsx-gutter + $icon-width) / 2);
    top: calc(($nsx-form-input-height - $icon-width) / 2);
  }

  &__add-btn {
    margin-top: 10px;
  }
}
