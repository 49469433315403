.chart-tooltip {
  background: white;
  border-radius: 5px;
  border: solid 1.2px $gray-dark;
  box-shadow: 0 2px 2px 0 rgba($brand-black, 0.4);
  font-size: 10px;
  line-height: 1.5;
  min-width: 120px;
  padding: 15px 12px;
}

.chart-tooltip {
  .chart-tooltip__item:not(:last-child) {
    margin-bottom: 10px;
  }

  .chart-tooltip__item {
    align-items: center;
    display: flex;
    min-height: 20px;
  }

  .chart-tooltip__item--translucent {
    opacity: .5;
  }

  .chart-tooltip__bubble {
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }

  .chart-tooltip__number {
    font-weight: bold;
    margin-bottom: -4px;
    margin-right: 5px;
  }

  .chart-tooltip__text {
    margin-bottom: -4px;
  }

  .chart-tooltip__label {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 9px;
  }
}
