.nsx-services-list {
  // Make it scrollable
  height: 100%;
  overflow: auto;

  padding-top: 162px;
  padding-bottom: 50px;
}

.nsx-services-list__container {
  position: relative;
}

.nsx-services-list__btn-container {
  position: absolute;
  top: 16px;
  right: -71px;
}
