.nsx-section-heading {
  background-color: $gray-dark;
  color: $brand-white;
  position: relative;
  height: 52px;
}

.nsx-section-heading--light {
  background-color: $gray-primary-light;
  color: $brand-black;
}

.nsx-section-heading__title {
  @extend %nsx-onboarding-nav-font-style;
  margin-top: 0; // override defaults set on h1, h2 etc.
  margin-bottom: 0; // override defaults set on h1, h2 etc.
  //line-height: $nsx-base-line-height; // override defaults set on h1, h2 etc.
  padding-top: 18px;
}

.nsx-section-heading__link {
  display: inline-block;
  padding-top: 18px;
  font-size: $nsx-font-size-16;
}
