.nsx-critical-indicators-chart__item {
  align-items: center;
  border-top: 1px solid $gray-primary-medium;
  display: flex;
  font-size: 18px;
  height: 56px;
  justify-content: space-between;
  line-height: 1.11;
  padding: 0 $dashboard-chart-padding;
}

.nsx-critical-indicators-chart__label {
  font-size: 18px;
  line-height: 1.11;
  padding-right: 40px;
}

.nsx-critical-indicators-chart__value {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.33;
  position: relative;
}
