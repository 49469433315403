.nsx-search-box {
  display: flex;
  align-items: center;
  float: right;
  font-size: $font-size-h3 - 2px;
  padding: calc($baseline / 4) 0 calc($baseline / 4) calc($baseline / 4);
}

.nsx-search-box__icon {
  color: $gray-bg;
  font-size: 28px;
  margin-right: -4px;
  margin-top: 2px;
  height: auto;
}

.nsx-search-box__input {
  background: transparent;
  display: inline-block;
  font-family: $font-family-sans-serif;
  min-width: 112px;
  max-width: 230px;

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $gray-bg;
    font-weight: 100;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $gray-bg;
    font-weight: 100;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $gray-bg;
    font-weight: 100;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $gray-bg;
    font-weight: 100;
  }
}
