nsx-list-card {
  display: block;
}

.nsx-list-card {
  $background-color: $brand-white;
  $active-background-color: $list-entry-hover-color;
  $box-shadow: 0.5px 2px 4px 0px rgba(0, 0, 0, 0.08);

  display: block;
  position: relative;

  & &__inner {
    overflow: hidden;
    border-radius: $nsx-base-border-radius;
    border-bottom: $nsx-base-border;
    box-shadow: $box-shadow;
    background-color: $background-color;
  }

  &:not(&--static):not(&--active):hover > &__inner {
    position: relative;
    top: 1px;
    box-shadow: none;
  }

  & &__arrow {
    $size: 30px;

    background-color: $active-background-color;
    box-shadow: $box-shadow;
    visibility: hidden;
    position: absolute;
    top: 20px;
    right: -15px;
    transform: rotate(45deg);
    width: $size;
    height: $size;
    z-index: -1;
  }

  &--active {
    & > .nsx-list-card__inner {
      top: 1px;
      box-shadow: none;
      background-color: $active-background-color;
    }

    & > .nsx-list-card__arrow {
      visibility: visible;
    }
  }

  &--active#{&}--static {
    .nsx-list-card__inner {
      background-color: $active-background-color;
    }
  }
}
