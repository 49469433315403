.nsx-onboarding-introduction-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}
