.notification-count {
  $height: 20px;
  background-color: $brand-danger;
  color: $brand-white;
  width: $height;
  height: $height;
  line-height: $height;
  font-size: 10px;
  text-align: center;
  border-radius: 50%;
}