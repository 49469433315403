.nsx-action-bar {
  height: $action-bar-height;
  background-color: $gray-primary-light;
  border-top: solid 1px $gray-bg;
  text-align: right;
  transform: translate3d(0, 0, 0);

  &--fixed-bottom {
    position: fixed;
    left: $sidebar-width-minimized;
    bottom: 0;
    right: 0;
    z-index: 10;

    .double-panel-right & {
      left: $sidebar-width-minimized + $double-panel-left-width;

      @media screen and (max-width: $screen-md-max) and (min-width: $screen-sm-min) {
        left: $sidebar-width-minimized + $double-panel-left-width-sm;
      }
    }

    @media screen and (max-width: $screen-sm) {
      left: 0;
    }
  }

  &--top {
    border-top: 0;
    border-bottom: solid 1px $gray-bg;
  }

  &__container {
    padding-left: $nsx-gutter;
    padding-right: $nsx-gutter;
  }

  &__actions {
    line-height: $action-bar-height;
    float: right;
  }
}
