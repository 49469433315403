.nsx-planned-interactions-chart {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.nsx-planned-interactions-chart__chart {
  position: relative;
  flex-basis: 200px;
  flex-grow: 0;
  flex-shrink: 0;
}

.nsx-planned-interactions-chart__tooltip {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 20px;
}

.nsx-planned-interactions-chart__labels {
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.nsx-planned-interactions-chart__total {
  font-size: 30px;
  font-weight: bold;
}
