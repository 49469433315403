.nsx_empty_search {
  color: $gray-dark;
  text-align: center;
  padding: 25px;

  & &__icon {
    font-size: 42px;
  }

  & &__notice {
    font-size: $nsx-font-size-20;
    padding-bottom: 20px;
  }

  & &__hint {
    font-size: $nsx-font-size-14;
  }
}
