.nsx-form-array {
  $icon-width: 25px; // this is set on the button so this variable isn't actually used
  @extend .clearfix;
  position: relative;
  margin-top: $nsx-form-small-gutter;

  &:first-child {
    margin-top: 0;
  }

  &__remove-btn {
    position: absolute;
    left: calc(-1 * ($nsx-gutter + $icon-width) / 2);
    top: calc(($nsx-form-input-height - $icon-width) / 2);
  }
  &__add-btn {
    position: absolute;
    right: calc(-1 * ($nsx-gutter + $icon-width) / 2);
    top: calc(($nsx-form-input-height - $icon-width) / 2);
  }
}
