.nsx-section {
  & &__header {
    @extend %style-dark-gray;

    padding: 0 30px;
    position: relative;
    height: 52px;
  }

  & &__title {
    @extend %nsx-onboarding-nav-font-style;
    margin-top: 0; // override defaults set on h1, h2 etc.
    margin-bottom: 0; // override defaults set on h1, h2 etc.
    padding-top: 18px;
    &.redaction-header {
      text-transform: uppercase;
    }
  }

  & &__body {
    padding-top: 28px;
    & .nsx-redaction-wrapper {
      padding-top: 15px;
      margin: auto;
      width: 60%;
    }
  }

  &--compact {
    $header-height: 26px;

    & .nsx-section__header {
      height: $header-height;
      margin: 0;
      padding: 0;
    }

    & .nsx-section__title {
      padding: 0 14px;
      font-size: 14px;
      font-weight: $nsx-font-weight-regular;
      text-transform: none;
      line-height: $header-height;
    }

    & .nsx-section__body {
      padding-top: 10px;
    }
  }

  &--rounded {
    & .nsx-section__header {
      border-radius: $nsx-small-border-radius;
    }
  }
}
