/* Variables within Bootstrap */
$font-size-base: 14px;
$font-size-small: 12px; // 14 * 0.85

.clearfix {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;
$screen-phone:               $screen-xs-min !default;

$screen-sm:                  800px !default;
$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;

$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;

$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$mobile-nav-breakpoint: $screen-sm;
$line-height-base: 1.2;
$line-height-base-24px: 24px;
$baseline: 20px;

/* MAIN COLORS */
$brand-primary:         #66cccc;
$brand-white:           #ffffff;
$brand-info:            $brand-primary;
$brand-success:         #6dc278;
$brand-minor:           #f9db00;
$brand-warning:         #ff9444;
$brand-danger:          #D10711;
$brand-purple:          #c352ff;
$brand-black:           #172b34;

/* GRAYS */
$gray-primary:          #d1cfcc;
$gray-primary-light:    #fbfaf9;
$gray-primary-medium:   darken($gray-primary-light, 12.8%); // #e1d9d2
$gray-primary-dark:     darken($gray-primary, 22%);         // #9c9791
$gray-primary-darker:   #8a9799;

// New colours - should reflect new palette - Neil said he'll we'll sort this at some stage
$gray-dark:             #798b93; // RENAME
$gray-medium-new:       #dad9d6; // RENAME
$gray-light-new:        #f7f7f7; // RENAME
$gray-light-2-new:      #ebebeb; // RENAME
$gray-border:           #ccc; // RENAME

// Accessibility colours
$pale-teal: #E0F5F5;
$color-red: #D10711;
$color-orange: #FF9444;
$color-lavander: #CFC2E3;
$color-green: #6DC278;
$color-violet: #7857FF;
$color-dark-grey: #57676D;
$color-light-grey: #C7CED1;
$color-lighter-grey: #F7F5F3;

/* TEXT COLORS */
$primary-text-color:    $brand-black;
$secondary-text-color:  $gray-dark;
$link-color:            $brand-primary;
$link-hover-color:      $gray-primary;

/* BACKGROUND COLORS */
$gray-bg:               #edeeef; // was #eae7e3
$gray-light-bg:         $gray-primary-light;
$body-bg:               $brand-white;
$onboarding-bg:         $gray-bg;

/* COMPONENTS COLOR */
$action-icon-color:        $gray-primary;
$table-border-color:       $gray-primary-medium;
$list-entry-hover-color:   darken($gray-primary-light, 2%);  // #F7F6F3
$even-row-bg-color:        $gray-light-new;
$dropdown-hover-color:     rgba($brand-primary, 0.2);
$sidebar-link-color:       $pale-teal;
$sidebar-link-hover-color: $brand-primary;
$emergency-color:         $color-red;

/* ALERT COLORS */
$alert-info-bg:         $brand-info;
$alert-success-bg:      $brand-success;
$alert-warning-bg:      $brand-warning;
$alert-danger-bg:       $brand-danger;

/* FONTS */
$font-size-xl: 48px;
$font-size-h1: 32px;
$font-size-h2: 26px;
$font-size-h3: 20px;
$font-size-h4: 16px;
$font-size-regular: 14px;

$font-size-medium: $font-size-h4;

$font-family-sans-serif: "FS Me", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;

/* GRIDS */
$grid-gutter-width: 10px;
$grid-float-breakpoint: 0; // Disable navbar responsiveness

$panel-heading-padding-vt: $grid-gutter-width;
$panel-heading-padding-hz: $grid-gutter-width;
$panel-heading-padding: $panel-heading-padding-vt $panel-heading-padding-hz;

$padding-vt: $baseline;
$padding-vt-sm: calc(#{$padding-vt}/2);
$padding-hz: $grid-gutter-width*2;
$padding-hz-sm: calc($padding-hz/4);

/* CONTAINERS SIZES */
$maintenance-banner-height:     50px;
$navbar-height:                 56px;
$top-actions-bar-height:        48px;
$top-actions-bar-height-mobile: 85px;
$bottom-actions-bar-height:     38px;
$sidebar-width:                 264px;
$sidebar-width-minimized:       56px;
$sidebar-sub-menu-height:       46px;
$double-panel-left-width:       392px;
$double-panel-left-width-sm:    330px;
$double-panel-right-width:      calc(100% - #{$double-panel-left-width});
$double-panel-right-width-sm:   calc(100% - #{$double-panel-left-width-sm});

$form-icon-col-width: 60px;

$loader-size: 90px;

/* MODALS SIZE */
$modal-large:  750px;
$modal-medium: 600px;
$modal-small:  450px;
$modal-alert-width:  488px;
$modal-alert-height:  217px;

/* BUTTONS */
$sm-round-btn: 55px;
$md-round-btn: 100px;

/* ICON SIZE */
$icon-size-md: $baseline*1.5;

/* NAVBAR */
$navbar-background: $brand-primary;
$navbar-foreground: $sidebar-link-color;

/* AVATARS SIZES */
$avatar-size-xs: $baseline*1.5;       // 30px
$avatar-size-small: 36px;             // 36px
$avatar-size-default: $baseline*2.5;  // 50px
$avatar-size-md: 60px;                // 60px - was 70px
$avatar-size-lg: $baseline*4.5;       // 90px
$avatar-size-xl: $baseline*6.25;      // 125px
$avatar-size-xxl: 160px;

/* MEDIA QUERIES */
$screen-xl: 1602px;
$dashboard-tablet-breakpoint: 992px;
$dashboard-desktop-breakpoint: 1300px;

/* OTHERS */
$box-shadow:          0 0 2px 1px rgba($primary-text-color, 0.35);
$box-stronger-shadow: -2px 3px 8px 1px rgba($primary-text-color, 0.55);
$box-shadow-top-bar:       0 0.0 5.5px 0 rgba($primary-text-color, 0.35);

$list-border: 1px solid $gray-bg;
$navbar-title-width: 240px;
$dashboard-chart-padding: 30px;
