.nsx-chart {
  background-color: #ffffff;
  border-radius: 4.1px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  position: relative;

  @media print {
    -webkit-print-color-adjust: exact;
    border: 1px solid $gray-primary-medium;
    margin: 0 auto;
    max-width: 70%;
  }
}

.nsx-chart__body {
  @extend .clearfix;
}

.nsx-chart__export {
  font-size: 26px;
  position: absolute;
  right: 10px;
  text-align: right;
  top: 10px;
  width: 200px;

  a {
    color: $primary-text-color;
  }

  @media print {
    display: none;
  }
}

.nsx-chart__title {
  padding: 0 30px;
  margin-bottom: 20px;

  * {
    margin: 0;
  }
}

.nsx-chart__title--holder {
  color: $primary-text-color;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  text-align: left;
  text-transform: uppercase;
}

.nsx-chart__timepicker {
  align-items: center;
  background-color: #fbfaf9;
  border-top: 1px solid $gray-primary-medium;
  display: flex;
  height: 50px;
  padding: 0 30px;

  @media print {
    -webkit-print-color-adjust: exact;
    background-color: #fbfaf9 !important;
  }
}

.nsx-chart__alert {
  margin-left: $dashboard-chart-padding;
  margin-right: $dashboard-chart-padding;
  float: left;
  clear: both;
  max-width: 100%;
}

.nsx-chart-log-tooltip-circle {
  display: inline-block;
  margin-right: 5px;
  border-radius: 10px;
  width: 9px;
  height: 9px;
}
