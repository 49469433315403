.nsx-help-banner {
  padding: 17px 20px;
  text-align: center;
  font-size: $nsx-font-size-16;

  @mixin faded-color-style($color) {
    background-color: rgba($color, 0.2);
    color: $color; 
  }

  &--info-faded {
    @include faded-color-style($brand-primary);
  }

  &--danger-faded {
    @include faded-color-style($brand-danger);
  }
}