$nsx-dashboard-stat-width: 120px;

.nsx-dashboard-stat {
  width: $nsx-dashboard-stat-width;
  text-align: center;
}

.nsx-dashboard-stat__value {
  font-size: 30px;
  font-weight: bold;
}

.nsx-dashboard-stat__title {
  font-size: 15px;
}
