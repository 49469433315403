// for now this still uses a lot of code
// in the standard applications/single_panel.scss

.nsx-single-panel {
  margin-bottom: 150px;

  .single-panel-body {
    background-color: $gray-primary-light;
  }
}

.nsx-single-panel__inner {
  padding: 100px;
}
