.nsx-interaction-icon {
  display: block;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;

  width: $nsx-interaction-icon-size;
  height: $nsx-interaction-icon-size;
  padding: $nsx-interaction-icon-padding;

  &--bordered {
    $border-size: 2px;

    border: $border-size solid $gray-primary;
    border-radius: 50%;
    padding: $nsx-interaction-icon-padding - $border-size;
  }

  @mixin icon-line {
    content: "";
    background-color: $gray-primary;
    height: 1000px;
    position: absolute;
    left: calc(50% - 1px);
    width: 2px;
  }

  &--line-to-bottom {
    &:before {
      @include icon-line;
      top: 100%;
    }
  }

  &--line-to-top {
    &:after {
      @include icon-line;
      bottom: 100%;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  & &__label {
    @include style-black;

    position: absolute;
    width: 18px;
    height: 18px;
    top: -6px;
    right: -6px;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;

    &--primary {
      @include style-primary;
    }
  }
}
