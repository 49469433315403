.style-selector {
  display: flex;
  align-items: center;
  margin-right: 40px;
  float: right;

  &__items {
    margin-left: 30px;
    display: flex;
    align-items: center;
  }

  &__item {
    $size: 16px;
    width: $size;
    height: $size;
    line-height: $size;
    font-size: $size * 0.75;
    text-align: center;
    font-weight: bold;
    border-color: $gray-dark;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    margin: 3px;
  
    &.selected {
      &:before {
        font-family: 'nsx-nourish-icons';
        content: "\e63b";
      }
    }
    &[data-background-color='#{$brand-primary}'] { background-color: $brand-primary; }
    &[data-background-color='#{$gray-medium-new}'] { background-color: $gray-medium-new; }
    &[data-background-color='#{$gray-primary-light}'] { background-color: $gray-primary-light; }
    &[data-color='#{$brand-black}'] { color: $brand-black; }
  }

  &__text-formatter {
    $size: 24px;
    width: $size;
    height: $size;
    line-height: $size;
    font-size: $size * 0.75;
    background-color: $brand-white;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    
    &.selected {
      background-color: $brand-primary;
    }
  }
}