.nsx-table-chart {
  max-height: 750px;
  overflow: scroll;
}

.nsx-table-chart__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.nsx-table-chart__list-item {
  border-top: 1px solid $gray-primary-medium;

  &:hover {
    background: $gray-primary-light;
  }
}

.nsx-table-chart__item {
  align-items: center;
  display: flex;
  min-height: 50px;
  padding: 0 $dashboard-chart-padding;
}

.nsx-table-chart__header {
  align-items: center;
  display: flex;
  padding: 0 0 6px;
  font-size: 18px;
  padding-left: $dashboard-chart-padding;
  padding-right: $dashboard-chart-padding;
}

.nsx-table-chart__label {
  flex: 1;
}

.nsx-table-chart__person {
  align-items: center;
  display: flex;
  flex-grow: 1;
  min-height: 60px;
}

.nsx-table-chart__person-icon {
  width: 36px;
}

.nsx-table-chart__tooltip {
  display: none;
  position: fixed;
  z-index: 1;
}

.nsx-table-chart__details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding-left: 20px;
}

.nsx-table-chart__status-text,
.nsx-table-chart__label:last-child,
.nsx-table-chart__chart {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 150px;
}

.nsx-table-chart__count {
  color: $brand-black;
  flex-basis: 0;
  flex-grow: 0;
  font-size: 12px;
  margin-right: 10px;
}
