.nsx-handover-interaction {
  $left-col-width: 54px;
  position: relative;

  &__arrow {
    visibility: hidden;
    position: absolute;
    top: 10px;
    right: -32px;
    width: 0;
    height: 0;
    border-left: solid $brand-white 30px;
    border-top: solid transparent 30px;
    border-bottom: solid transparent 30px;
  }

  &--active &__arrow {
    visibility: visible;
  }

  &__inner {
    overflow: hidden;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  &__main {
    display: flex;
    align-items: center;
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $nsx-font-size-16;
    min-width: 79px;
    padding-left: 10px;
    padding-right: 10px;

    &:focus {
      color: $brand-primary;
    }

    &:hover {
      color: $gray-primary;
    }
  }

  &__time {
    margin-top: 4px;
    margin-left: auto;
    color: $gray-dark;
    font-size: $nsx-font-size-14;
    text-align: right;
    white-space: pre-line;
  }

  &__note {
    display: flex;
  }

  &__note-spacer {
    width: $left-col-width;
    height: 1px;
  }

  &__note-text {
    width: calc(100% - #{$left-col-width});
    font-size: $nsx-font-size-16;
    padding-right: 12px;
  }
}
