.nsx-permissions-cell {
  padding: 5px 10px;
  color: $gray-dark;
  font-weight: normal;
}

.nsx-permissions-cell--editing {
  background-color: rgba($brand-white, 0.5) !important;
}

.nsx-permissions-cell--body {
  font-size: $nsx-font-size-12;
}

.nsx-permissions-cell__one-line-item {
  padding: 24px $nsx-gutter 0;
}

.nsx-permissions-cell__cant-manage {
  padding: 28px 5px 0;
  text-align: center;
}
