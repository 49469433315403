$nsx-dashboard-header-height: 100px;
$nsx-dashboard-header-height-mobile: 100px;

$nsx-dashboard-sidebar-selected: #f0f0ef;

.nsx-dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0;
  padding-top: $navbar-height + $nsx-dashboard-header-height-mobile;

  &.vue-insights {
    padding-top: $navbar-height;
  }

  @media(min-width: $dashboard-tablet-breakpoint) {
    padding-top: $navbar-height + $nsx-dashboard-header-height;

    &.vue-insights {
      padding-top: $navbar-height;
    }
  }

  @media print {
    padding-top: 0;
  }

  @media (max-width: $dashboard-tablet-breakpoint) {
    &.vue-insights {
      .nsx-dashboard-body {
        margin-left: 330px;
      }
    }
  }

  @media (max-width: 800px) {
    &.vue-insights {
      .nsx-dashboard-body {
        display: none;
      }
    }
  }
}

.nsx-dashboard__header {
  left: 0;
  position: absolute;
  top: $navbar-height;
  width: 100%;
  z-index: 5;
  height: $nsx-dashboard-header-height-mobile;

  .maintenance-banner-show & {
    top: $navbar-height + $maintenance-banner-height;
  }

  @media(min-width: $dashboard-tablet-breakpoint) {
    height: $nsx-dashboard-header-height;
  }
}

.nsx-dashboard__content {
  flex-grow: 1;
  display: flex;
  position: relative;
  top: 0;

  .maintenance-banner-show & {
    top: $maintenance-banner-height;
  }

  @media (max-width: $dashboard-tablet-breakpoint) {
    flex-direction: column;
  }
}

.nsx-dashboard__body {
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  padding: 10px 0 70px;
}

.nsx-dashboard__sidebar {
  flex-basis: 380px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  position: relative;

  &.vue-insights {
    flex-basis: 392px;
  }

  @media (min-width: $dashboard-tablet-breakpoint) and (max-width: 1200px) {
    &.vue-insights {
      flex-basis: 330px;
    }
  }

  @media (max-width: $dashboard-tablet-breakpoint) {
    flex-basis: 0;
    &.vue-insights {
      flex-basis: 0;
    }
  }
}

.scrollable-container-dashboard {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
