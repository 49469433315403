$_icon-selectors: (
  "[class^='nr-icon']",
  ".icon",
  "[class*='nr-icon']",
  "[class^='fa-icon']",
  "[class^='nsx-font-icon']",
  ".fa"
);

$all-icon-selectors: _group-selectors($_icon-selectors);

%nsx-select-style {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    background-image: url("#{$nsx-image-path}icons/keyboard-arrow-down.svg");
    background-repeat: no-repeat;
    background-position: 96% center;
    -webkit-appearance: none;
    padding-right: 30px;
  }
}

%text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.additional-info {
  color: $brand-primary;
}

// Utilities

.u-clearfix { @extend .clearfix; }

// Margin
.u-margin-top--auto { margin-top: auto; }
.u-margin-top--0 { margin-top: 0; }
.u-margin-top--5 { margin-top: 5px; }
.u-margin-top--10 { margin-top: 10px; }
.u-margin-top--12 { margin-top: 12px; }
.u-margin-top--15 { margin-top: 15px; }
.u-margin-top--20 { margin-top: 20px; }
.u-margin-top--25 { margin-top: 25px; }
.u-margin-top--30 { margin-top: 30px; }

.u-margin-right--auto { margin-right: auto; }
.u-margin-right--0 { margin-right: 0; }
.u-margin-right--5 { margin-right: 5px; }
.u-margin-right--10 { margin-right: 10px; }
.u-margin-right--12 { margin-right: 12px; }
.u-margin-right--15 { margin-right: 15px; }
.u-margin-right--20 { margin-right: 20px; }
.u-margin-right--25 { margin-right: 25px; }
.u-margin-right--30 { margin-right: 30px; }

.u-margin-bottom--auto { margin-bottom: auto; }
.u-margin-bottom--0 { margin-bottom: 0; }
.u-margin-bottom--5 { margin-bottom: 5px; }
.u-margin-bottom--10 { margin-bottom: 10px; }
.u-margin-bottom--12 { margin-bottom: 12px; }
.u-margin-bottom--15 { margin-bottom: 15px; }
.u-margin-bottom--20 { margin-bottom: 20px; }
.u-margin-bottom--25 { margin-bottom: 25px; }
.u-margin-bottom--30 { margin-bottom: 30px; }

.u-margin-left--auto { margin-left: auto; }
.u-margin-left--0 { margin-left: 0; }
.u-margin-left--5 { margin-left: 5px; }
.u-margin-left--10 { margin-left: 10px; }
.u-margin-left--12 { margin-left: 12px; }
.u-margin-left--15 { margin-left: 15px; }
.u-margin-left--20 { margin-left: 20px; }
.u-margin-left--25 { margin-left: 25px; }
.u-margin-left--30 { margin-left: 30px; }
.u-margin-left--45 { margin-left: 45px; }


// Padding
.u-padding-top--0 { padding-top: 0; }
.u-padding-top--5 { padding-top: 5px; }
.u-padding-top--10 { padding-top: 10px; }
.u-padding-top--15 { padding-top: 15px; }
.u-padding-top--20 { padding-top: 20px; }
.u-padding-top--25 { padding-top: 25px; }
.u-padding-top--30 { padding-top: 30px; }

.u-padding-right--0 { padding-right: 0; }
.u-padding-right--5 { padding-right: 5px; }
.u-padding-right--10 { padding-right: 10px; }
.u-padding-right--15 { padding-right: 15px; }
.u-padding-right--20 { padding-right: 20px; }
.u-padding-right--25 { padding-right: 25px; }
.u-padding-right--30 { padding-right: 30px; }

.u-padding-bottom--0 { padding-bottom: 0; }
.u-padding-bottom--5 { padding-bottom: 5px; }
.u-padding-bottom--10 { padding-bottom: 10px; }
.u-padding-bottom--15 { padding-bottom: 15px; }
.u-padding-bottom--20 { padding-bottom: 20px; }
.u-padding-bottom--25 { padding-bottom: 25px; }
.u-padding-bottom--30 { padding-bottom: 30px; }
.u-padding-bottom--40 { padding-bottom: 40px; }
.u-padding-bottom--60 { padding-bottom: 60px; }

.u-border { border: $nsx-base-border; }
.u-border-top { border-top: $nsx-base-border; }
.u-border-bottom { border-bottom: $nsx-base-border; }
.u-border-right { border-right: $nsx-base-border; }
.u-border-left { border-left: $nsx-base-border; }

.u-padding-left--0 { padding-left: 0; }
.u-padding-left--5 { padding-left: 5px; }
.u-padding-left--10 { padding-left: 10px; }
.u-padding-left--15 { padding-left: 15px; }
.u-padding-left--20 { padding-left: 20px; }
.u-padding-left--25 { padding-left: 25px; }
.u-padding-left--30 { padding-left: 30px; }

// Text
.u-text-align--left { text-align: left; }
.u-text-align--right { text-align: right; }
.u-text-align--center { text-align: center; }

.u-color--notice,
.u-color--primary {
  color: $brand-primary;
}
.u-color--dark-gray { color: $gray-dark; }

.u-font-size--16 { font-size: $nsx-font-size-16; }

// Display / Flexbox
.u-display--inline { display: inline; }

.u-flex { display: flex; }
.u-flex-center { display: flex; align-items: center; }
.u-flex-basis--25 { flex-basis: 25%; }
.u-flex-basis--40 { flex-basis: 40%; }
.u-flex-basis--50 { flex-basis: 50%; }
.u-flex-basis--60 { flex-basis: 60%; }
.u-flex-basis--100 { flex-basis: 100%; }
.u-flex-grow--0 { flex-grow: 0; }
.u-flex-grow--1 { flex-grow: 1; }
.u-flex-shrink--0 { flex-shrink: 0; }
.u-flex-shrink--1 { flex-shrink: 1; }
.u-font-weight--bold { font-weight: 700; }

//General
.u-overflow--scroll { overflow: scroll; }

.u-render-line-breaks {
  white-space: pre-wrap;
}

.u-print-hide {
  @media print {
    display: none !important;
  }
}

.u-print-show {
  @media print {
    display: block !important;
  }
}

.u-opacity--60 { opacity: 0.6; }
