.nsx-dashboard-body {
  background-color: $gray-primary-light;
  min-height: 100%;
  padding: 10px 0 70px;

  .custom-checkbox-group {
    margin-bottom: -35px;
  }
}

.nsx-dashboard-body__chart {
  margin-bottom: 10px;
}

.nsx-dashboard-body__timepicker {
}

.nsx-dashboard-body__column {
  display: inline-block;
  padding: 0 10px 20px;
  vertical-align: top;
  width: 49%;

  @media (max-width: $dashboard-desktop-breakpoint) {
    width: 100%;
  }
}

.nsx-dashboard-body__full-width-column {
  display: block;
  padding: 0 10px 20px;
  width: 100%;
}
