.nsx-dashboard-picker {
  display: inline-block;
  max-width: 100%;
  position: relative;
}

.nsx-dashboard-picker__popup {
  background: white;
  box-shadow: $box-shadow-top-bar;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: calc(100% + 5px);
  width: 300px;
  z-index: 1;
}

.nsx-dashboard-picker__toggle {
  align-items: center;
  background: transparent;
  border: none;
  display: inline-flex;
  font-size: 30px;
  font-weight: bold;
  justify-content: center;
  max-width: 100%;
  min-height: 44px;
  padding: 0;
}

.nsx-dashboard-picker__toggle-inside {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 20px);
}

.nsx-dashboard-picker__toggle--small {
  font-size: 20px;
}

.nsx-dashboard-picker__toggle:after {
  border-color: #000333 transparent transparent transparent;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  content: '';
  height: 0;
  margin-left: 20px;
  width: 0;
}
