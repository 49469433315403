$nsx-card-one-line-height: 100px;

.nsx-card {
  background-color: $brand-white;
  border-radius: $nsx-base-border-radius;
  position: relative;

  & + .nsx-card,
  & + .card {
    margin-top: $nsx-gutter;
  }

  a:hover {
    color: $brand-black;
  }

  @media print {
    .nsx-btn,
    .action-btn {
      display: none;
    }
  }




  &__header {
    @include nsx-list-entry-builder($nsx-card-left-col-width, $nsx-card-actions-col-width);
    background-color: $gray-primary-light;
  }

  &__header-inner {
    @extend %flex-wrapper-center;
    min-height: 100px;

    .left-col {
      text-align: right;
      padding-right: calc($nsx-gutter / 2);
    }

    .right-col {
      padding-right: $nsx-gutter;
      .action-btn:not(:first-child) {
        margin-left: calc($baseline / 4);
      }
    }
  }

  &__title {
    display: block;
    font-size: $nsx-font-size-20;
    width: 100%;
  }

  &__body {
    @extend .clearfix;
    border-top: 1px solid $gray-bg;
    overflow-x: hidden;
    width: 100%;
    padding: 10px 0 $baseline;
  }

  &__body-wrapper {
    padding: $baseline $baseline * 2 $baseline * 2;
  }

  &__section-heading {
    @include nsx-list-entry-builder($nsx-card-left-col-width, $nsx-card-left-col-width);
    @extend %flex-wrapper-center;
  }

  &__entry {
    @include nsx-list-entry-builder($nsx-card-left-col-width, $nsx-card-left-col-width);
  }

  &__entry-header {
    @extend %flex-wrapper-center;

    @media print {
      //border-bottom: $nsx-base-border;
      border-top: $nsx-base-border;
    }

    .right-col {
      text-align: center;
    }
  }

  &__entry-body {
    @extend %flex-wrapper-center;
    padding: $baseline 0;

    .left-col {
      align-self: flex-start;
    }
  }

  &__note {
    min-height: $nsx-editor-height + 40px;
  }

  &__entry-body--one-line {
    line-height: 100px;
  }

  &__row {
    padding: $baseline 0;
  }

  &__subtitle {
    font-size: $nsx-font-size-16;
    margin: 0 0 10px 0;
  }

  &__one-line-entry {
    @include nsx-list-entry-builder($nsx-card-left-col-width, 40px);
    @extend %flex-wrapper-center;
    line-height: $nsx-card-one-line-height;
    font-size: $nsx-font-size-16;

    &:last-child {
      border-top: $nsx-base-border;
    }
  }

  &__one-line-title {
    font-size: $nsx-font-size-18;
    font-weight: bold;
    margin-right: 10px;
  }

  &__one-line-actions {
    float: right;
    line-height: $nsx-card-one-line-height;
  }
}
