.nsx-menu-item {
  @extend %style-white;

  cursor: pointer;
  display: inline-block;
  width: 100%;
  padding: 12px;

  &:hover:not(&__disabled) {
    background-color: $gray-primary;
    color: initial !important;
  }

  &:focus:not(&__disabled), &:visited:not(&__disabled), &:active:not(&__disabled) {
    @extend %style-white;
  }

  &__disabled {
    cursor: not-allowed;
    color: $gray-primary !important;

    &:hover, &:focus, &:visited, &:active {
      color: $gray-primary !important;
    }
  }

  & &--content {
    text-align: left;
    font-size: $nsx-font-size-16;
    font-weight: $nsx-font-weight-regular;
  }
}
