.nsx-interaction-header-content {
  display: flex;
  align-items: center;
  height: 34px;
  margin-top: 9px;

  &__title {
    display: inline-flex;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
  }
}
