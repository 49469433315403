.nsx-avatar-with-text {
  display: flex;
  align-items: center;
  overflow: hidden;

  &--avatar {
    flex: 0 0 60px;
  }

  &--text {
    flex: 1 1 100%;
    padding-left: 25px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
