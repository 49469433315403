/////*  TEXT  */////
.bold-font {
  font-weight: bold;
}

.regular-font {
  font-weight: normal;
}

.light-font {
  font-weight: 100;
}

.gray-text {
  color: $gray-primary-dark!important;
}

.sec-gray-text {
  color: $secondary-text-color!important;
}

.primary-text {
  color: $brand-primary;
}

.text-base {
  font-size: $font-size-base;
}

.text-h4 {
  font-size: $font-size-h4;
}

.text-h3 {
  font-size: $font-size-h3;
}

.text-h2 {
  font-size: $font-size-h2;
}

.text-h1 {
  font-size: $font-size-h1;
}

.text-md {
  font-size: 32px;
  line-height: 1;
}

.text-xl {
  font-size: 55px;
  line-height: 1;
}

.text-warning {
  color: $brand-warning;
}

.text-alarm {
  color: $brand-danger;
}

.text-success {
  color: $brand-success;
}

.text-sm {
  font-size: $font-size-small;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.no-selectable-text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.placeholder-text {
  color: $gray-primary-dark;
  font-style: italic;
  font-weight: 100;
}

.font-style-normal {
  font-style: normal;
}

/////*  LINKS  */////
.no-link-color {
  color: initial!important;

  &:active, &:focus {
    color: initial!important;
  }
}

.no-link-decoration {
  text-decoration: none!important;

  &:hover, &:active, &:focus, &:visited {
    text-decoration: none!important;
  }
}

/////*  MARGINS  */////
.no-margin {
  margin: 0!important;
}

.margin-lg {
  margin: $baseline!important;
}

.no-margin-top {
  margin-top: 0!important;
}

.no-margin-bottom {
  margin-bottom: 0!important;
}

.no-margin-left {
  margin-left: 0!important;
}

.no-margin-right {
  margin-right: 0!important;
}

.margin-top-xs {
  margin-top: calc($baseline / 4)!important;
}

.margin-bottom-xs {
  margin-bottom: calc($baseline / 4)!important;
}

.margin-top-bottom-xs {
  margin-top: calc($baseline / 4)!important;
  margin-bottom: calc($baseline / 4)!important;
}

.margin-left-xs {
  margin-left: calc($baseline / 4)!important;
}

.margin-right-xs {
  margin-right: calc($baseline / 4)!important;
}

.margin-top-sm {
  margin-top: calc($baseline / 2)!important;
}

.margin-bottom-sm {
  margin-bottom: calc($baseline / 2)!important;
}

.margin-top-bottom-sm {
  margin-top: calc($baseline / 2)!important;
  margin-bottom: calc($baseline / 2)!important;
}

.margin-left-sm {
  margin-left: calc($baseline / 2)!important;
}

.margin-right-sm {
  margin-right: calc($baseline / 2)!important;
}

.margin-top-md {
  margin-top: $baseline*0.75!important;
}

.margin-bottom-md {
  margin-bottom: $baseline*0.75!important;
}

.margin-top-bottom-md {
  margin-top: $baseline*0.75!important;
  margin-bottom: $baseline*0.75!important;
}

.margin-left-md {
  margin-left: $baseline*0.75!important;
}

.margin-right-md {
  margin-right: $baseline*0.75!important;
}

.margin-top-lg {
  margin-top: $baseline!important;
}

.margin-bottom-lg {
  margin-bottom: $baseline!important;
}

.margin-top-xxlg {
  margin-top: $baseline*2 !important;
}

.margin-bottom-xxxlg {
  margin-bottom: $baseline*3 !important;
}

.margin-top-bottom-lg {
  margin-top: $baseline!important;
  margin-bottom: $baseline!important;
}

.margin-left-lg {
  margin-left: $baseline!important;
}

.margin-right-lg {
  margin-right: $baseline!important;
}

/////*  PADDING  */////
.no-padding {
  padding: 0!important;
}

.no-padding-left {
  padding-left: 0!important;
}

.no-padding-right {
  padding-right: 0!important;
}

.no-padding-bottom {
  padding-bottom: 0!important;
}

.no-padding-top {
  padding-top: 0!important;
}

.padding-top-xs {
  padding-top: calc($baseline / 4)!important;
}

.padding-bottom-xs {
  padding-top: calc($baseline / 4)!important;
}

.padding-left-xs {
  padding-left: calc($baseline / 4)!important;
}

.padding-right-xs {
  padding-right: calc($baseline / 4)!important;
}

.padding-top-bottom-xs {
  padding-top: calc($baseline / 4)!important;
  padding-bottom: calc($baseline / 4)!important;
}

.padding-top-sm {
  padding-top: calc($baseline / 2)!important;
}

.padding-bottom-sm {
  padding-top: calc($baseline / 2)!important;
}

.padding-left-sm {
  padding-left: calc($baseline / 2)!important;
}

.padding-right-sm {
  padding-right: calc($baseline / 2)!important;
}

.padding-top-bottom-sm {
  padding-top: calc($baseline / 2)!important;
  padding-bottom: calc($baseline / 2)!important;
}

.padding-top-md {
  padding-top: $baseline*0.75!important;
}

.padding-bottom-md {
  padding-top: $baseline*0.75!important;
}

.padding-left-md {
  padding-left: $baseline*0.75!important;
}

.padding-right-md {
  padding-right: $baseline*0.75!important;
}

.padding-top-bottom-md {
  padding-top: $baseline*0.75!important;
  padding-bottom: $baseline*0.75!important;
}

.padding-top-lg {
  padding-top: $baseline!important;
}

.padding-bottom-lg {
  padding-top: $baseline!important;
}

.padding-left-lg {
  padding-left: $baseline!important;
}

.padding-right-lg {
  padding-right: $baseline!important;
}

.padding-top-bottom-lg {
  padding-top: $baseline!important;
  padding-bottom: $baseline!important;
}

.padding-top-xl {
  padding-top: $baseline*1.25!important;
}

.padding-bottom-xl {
  padding-bottom: $baseline*1.25!important;
}

.padding-left-xl {
  padding-left: $baseline*1.25!important;
}

.padding-right-xl {
  padding-right: $baseline*1.25!important;
}


/////*  SIZES  */////
.full-height {
  height: 100%!important;
}

.full-width {
  width: 100%!important;
}

/////* FLOAT */////
.no-float {
  float: none!important;
}

/////*  BORDERS  */////
.border-top-xs {
  border-top: 1px solid $gray-bg;
}

.border-bottom-xs {
  border-bottom: 1px solid $gray-bg;
}

.border-left-xs {
  border-left: 1px solid $gray-bg;
}

.border-right-xs {
  border-right: 1px solid $gray-bg;
}

.border-top-bottom-xs {
  border-top: 1px solid $gray-bg;
  border-bottom: 1px solid $gray-bg;
}

.no-border {
  border: 0!important;
}

.no-border-top {
  border-top: none!important;
}


/////*  CONTAINERS  */////
.scrollable-container {
  height: 100%;
  overflow-y: auto;
}


/////*  DISPLAYS  */////
.display-table-row {
  display: table-row;
  vertical-align: middle;
}

/////*  BUTTONS  */////
.round-btn-md {
  width: $md-round-btn;
  height: $md-round-btn;
}


/////*  COLUMNS  */////
@mixin inline-column {
  display: inline-block;
  float: none;
  vertical-align: middle;
  margin: 0 -0.125em;
}


/////*  FADES  */////
@mixin fade-div($start-color, $end-color, $height, $position) {
  $side: 0;
  @if $position == "after" { $side: -1; }
  $_start_color: rgba(red($start-color), green($start-color), blue($start-color), 1+($side));
  $_end_color: rgba(red($end-color), green($end-color), blue($end-color), 0+(-$side));

  &:#{$position} {
    background: -moz-linear-gradient(top, $_start-color 0%, $_end-color 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $_start-color), color-stop(100%, $_end-color)); /* Chrome,Safari4+ */
    background: linear-gradient(to bottom, $_start-color 0%, $_end-color 100%); /* W3C */
    content:"";
    display: block;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=0 ); /* IE6-9 */
    height: $height;
    @if $position == "before" {
      top: 0;
      bottom: initial;
    } @else {
      top: initial;
      bottom: 0;
    }
    left: 0;
    position: absolute;
    pointer-events: none;
    width: 100%;
    *zoom:1;
    zoom:1;
  }
}


/////*  ARROWS  */////
@mixin arrow-right($top, $right, $bottom, $left, $color) {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $top $right $bottom 0;
  border-color: transparent $color transparent transparent;
}

@mixin arrow($dir, $size, $color) {
  border: $size transparent solid;

  @if ($dir == "top" or $dir == "right") {
    @if ($dir == "top") {
      border-bottom-color: $color;
    }
    @else if ($dir == "right") {
      border-left-color: $color;
    }
  }
  @else if ($dir == "bottom" or $dir == "left") {
    @if ($dir == "bottom") {
      border-top-color: $color;
    }
    @else if ($dir == "left") {
      border-right-color: $color;
    }
  }
}


/////*  ALIGN ELEMENTS  */////
@mixin align-vertically-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.vertically-aligned-td {
  @include align-vertically-table-cell;
}

.v-align-middle {
  display: inline-block;
  vertical-align: middle;
}


/////* DISPLAY */////
.display-inline-block {
  display: inline-block!important;
}


/////*  ICONS  */////
.icon-with-border {
  padding: 2px;
  border: 1px solid $gray-bg;
  border-radius: 50%;

  .state-icon.state-circle {
    line-height: $avatar-size-small!important;
    height: $avatar-size-small!important;
    width: $avatar-size-small!important;
  }
}


/////*  MISC  */////
@mixin linking-line ($to_direction, $height, $color) {
  @if ($to_direction == "bottom") {
    top: calc(100% + 2px);
  } @else {
    bottom: 100%;
  }

  background-color: $color;
  content: "";
  left: calc(50% - 1px);
  height: $height;
  width: 2px;
  position: absolute;
}
.no-scroll {
  overflow: hidden;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.divider {
  margin-top: calc($baseline / 2);
  margin-bottom: calc($baseline / 2);
  text-align: center;
}
.negative-margin-top-20 {
  margin-top: -20px;
}
.negative-margin-top-30 {
  margin-top: -30px;
}
.margin-left-45{
  margin-left: 45px;
}
