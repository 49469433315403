.nsx-avatar {
  border-radius: 50%;
}

.nsx-avatar--medium {
  height: 60px;
  width: 60px;
}

.nsx-avatar--with-border {
  padding: 2px;
  border: 1px solid $gray-bg;
}
