.nsx-maintenance-banner {
  @include style-warning;
  position: fixed;
  height: $maintenance-banner-height;
  width: calc(100% - #{$sidebar-width-minimized});
  line-height: calc($maintenance-banner-height / 3);
  text-align: center;
  z-index: 999999;
  left: 0;
  width: 100%;

  @media (max-width: $screen-xs) {
    font-size: 11px;
  }

  @media (max-width: $screen-sm) {
    width: 100%;
  }

  @media (min-width: $screen-sm) {
    line-height: calc($maintenance-banner-height / 2);
  }

  @media (min-width: 1416px) {
    line-height: $maintenance-banner-height;
  }

  &__text {
    font-weight: 700;
    flex-grow: 1;
  }
}
