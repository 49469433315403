.nsx-list-item-text {
  overflow: hidden;

  &__primary {
    font-size: $nsx-font-size-18;
    line-height: $nsx-font-size-18 * 1.2;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media screen and (-webkit-min-device-pixel-ratio:0) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }

  &__secondary,
  &__introduction {
    font-size: $nsx-font-size-14;
    line-height: $nsx-font-size-14 * 1.2;
    color: $gray-dark;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__secondary {
    margin-top: 4px;
  }

  &__introduction {
    margin-bottom: 4px;
  }
}
