@import "../../assets/stylesheets/bootstrap/variables";
@import "../../assets/stylesheets/application/__builders";
@import "../../assets/stylesheets/application/_helpers";
@import "app/assets/stylesheets/application_nsx/base/__nsx_variables.scss";
@import "app/assets/stylesheets/application_nsx/base/_nsx_builders.scss";
@import "app/assets/stylesheets/application_nsx/base/_nsx_helpers.scss";
@import "app/assets/stylesheets/application_nsx/base/form.scss";
@import "app/assets/stylesheets/application_nsx/base/_styles.scss";
@import "app/assets/stylesheets/application_nsx/base/_global.scss";
@import "app/assets/stylesheets/application_nsx/base/container.scss";
@import "app/assets/stylesheets/application_nsx/base/typography.scss";
@import "app/assets/stylesheets/application_nsx/base/_nsx-font-icon.scss";
@import "app/assets/stylesheets/application_nsx/base/nsx_icon_btn.scss";
@import "app/assets/stylesheets/application_nsx/base/icons.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_editor.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_chart.scss";
@import "app/assets/stylesheets/application_nsx/components/device_management.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_critical_indicators_chart.scss";
@import "app/assets/stylesheets/application_nsx/components/notification_count.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_permissions_header_cell.scss";
@import "app/assets/stylesheets/application_nsx/components/date-range-picker.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_need_review.scss";
@import "app/assets/stylesheets/application_nsx/components/client_types.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_dropdown_option_icon.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_dashboard.scss";
@import "app/assets/stylesheets/application_nsx/components/label.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_table_chart.scss";
@import "app/assets/stylesheets/application_nsx/components/form_array.scss";
@import "app/assets/stylesheets/application_nsx/components/form_phone_array.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_avatar.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_text_with_badges.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_menu_btn.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_handover_flag.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx-empty-page.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_new_flag.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_list_item_text.scss";
@import "app/assets/stylesheets/application_nsx/components/intro_arrow.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_sidebar.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_dashboard_table.scss";
@import "app/assets/stylesheets/application_nsx/components/beamer.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_rotas.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_new_modal.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_dashboard_header.scss";
@import "app/assets/stylesheets/application_nsx/components/action_bar.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_handover_card.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_scrolling_table.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_handover_interaction.scss";
@import "app/assets/stylesheets/application_nsx/components/onboarding_info.scss";
@import "app/assets/stylesheets/application_nsx/components/accordion.scss";
@import "app/assets/stylesheets/application_nsx/components/shared/nsx_list_panel/nsx_list_panel_item.scss";
@import "app/assets/stylesheets/application_nsx/components/shared/nsx_list_panel/nsx_list_panel.scss";
@import "app/assets/stylesheets/application_nsx/components/shared/nsx_list_panel_item_search_header.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_navbar.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_alarm_notifications.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx-dropdown-filter-btn.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_emergency_info.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_count_icon.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_planned_interactions_chart.scss";
@import "app/assets/stylesheets/application_nsx/components/section_heading.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_badge.scss";
@import "app/assets/stylesheets/application_nsx/components/table_axis_label.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_dashboard_chart.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_search_dropdown.scss";
@import "app/assets/stylesheets/application_nsx/components/range-datepicker.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_dashboard_stat.scss";
@import "app/assets/stylesheets/application_nsx/components/risk_score.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_person_table_cell.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_card_form_group.scss";
@import "app/assets/stylesheets/application_nsx/components/speech_intro.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_icon_with_text.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_dashboard_body.scss";
@import "app/assets/stylesheets/application_nsx/components/organisation/services/nsx_list_panel_item_service.scss";
@import "app/assets/stylesheets/application_nsx/components/organisation/services/nsx_services_list.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_maintenance_banner.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_add_menu.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_input_checkboxes.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_text_container.scss";
@import "app/assets/stylesheets/application_nsx/components/style_selector.scss";
@import "app/assets/stylesheets/application_nsx/components/form_group.scss";
@import "app/assets/stylesheets/application_nsx/components/onboarding_page_content.scss";
@import "app/assets/stylesheets/application_nsx/components/resident_dashboard.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_card.scss";
@import "app/assets/stylesheets/application_nsx/components/toggled_form.scss";
@import "app/assets/stylesheets/application_nsx/components/permissions.scss";
@import "app/assets/stylesheets/application_nsx/components/chart_timepicker.scss";
@import "app/assets/stylesheets/application_nsx/components/form_overlay.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_initials.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_input_checkbox.scss";
@import "app/assets/stylesheets/application_nsx/components/single_panel.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_help_banner.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_table.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_dashboard_sidebar.scss";
@import "app/assets/stylesheets/application_nsx/components/family_portal.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_avatar_with_text.scss";
@import "app/assets/stylesheets/application_nsx/components/modal.scss";
@import "app/assets/stylesheets/application_nsx/components/chart_tooltip.scss";
@import "app/assets/stylesheets/application_nsx/components/person_badges.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_permission_cell.scss";
@import "app/assets/stylesheets/application_nsx/components/onboarding_section.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_dashboard_chart_loader.scss";
@import "app/assets/stylesheets/application_nsx/components/onboarding_nav.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_interaction_header_content.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_search_box.scss";
@import "app/assets/stylesheets/application_nsx/components/onboarding_introduction_container.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_dashboard_picker.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_wait_screen.scss";
@import "app/assets/stylesheets/application_nsx/components/nsx_permission_title_row.scss";
@import "app/assets/javascripts/angular/components/layout/forms/nsx_form.scss";
@import "app/assets/javascripts/angular/components/layout/nsx_section.scss";
@import "app/assets/javascripts/angular/components/layout/buttons/nsx_button.scss";
@import "app/assets/javascripts/angular/components/layout/nsx_panel.scss";
@import "app/assets/javascripts/angular/components/layout/nsx_sidebar/nsx_sidebar_item.scss";
@import "app/assets/javascripts/angular/components/layout/nsx_select/nsx_select_option.scss";
@import "app/assets/javascripts/angular/components/layout/nsx_select.scss";
@import "app/assets/javascripts/angular/components/layout/nsx_separator.scss";
@import "app/assets/javascripts/angular/components/layout/nsx_menu_item.scss";
@import "app/assets/javascripts/angular/components/layout/nsx_dropdown.scss";
@import "app/assets/javascripts/angular/components/layout/nsx_list_card.scss";
@import "app/assets/javascripts/angular/components/layout/nsx_title.scss";
@import "app/assets/javascripts/angular/components/shared/nsx_alert.scss";
@import "app/assets/javascripts/angular/components/shared/nsx_password_strength.scss";
@import "app/assets/javascripts/angular/components/shared/timeline/nsx_empty_search.scss";
@import "app/assets/javascripts/angular/components/shared/timeline/nsx_interaction_icon.scss";
@import "app/assets/javascripts/angular/components/shared/timeline/nsx_interaction_list_item.scss";
@import "app/assets/javascripts/angular/components/shared/timeline/nsx_interaction_list_item/nsx_interaction_list_item_quick_close.scss";
@import "app/assets/javascripts/angular/components/shared/timeline/nsx_interaction_search_result.scss";
@import "app/assets/javascripts/angular/components/permissions/nsx_permission_row.scss";
@import "../../assets/stylesheets/nsx_hacks.scss";
@import "c3/c3.min.css";
@import "jquery-date-range-picker/dist/daterangepicker.min.css";
