.nsx-form-overlay {
  position: fixed;
  padding-bottom: 64px;
  top: $onboarding-nav-height + $navbar-height;
  left: $sidebar-width-minimized;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $onboarding-bg;

  @media screen and (max-width: $screen-sm) {
    left: 0;
  }
}
