@mixin style-primary {
  color: $brand-black;
  background-color: $brand-primary;
  border-color: $brand-primary;
}

%style-primary {
  @include style-primary;
}

@mixin style-primary-darken {
  color: $brand-white;
  background-color: darken($brand-primary, $nsx-hover-darken);
  border-color: darken($brand-primary, $nsx-hover-darken);
}

%style-primary-darken {
  @include style-primary-darken;
}

@mixin style-primary-inverted {
  color: $brand-white;
  border-color: $brand-black;
  background-color: $brand-black;
}

%style-primary-inverted {
  @include style-primary-inverted;
}

@mixin style-danger {
  color: $brand-white;
  background-color: $brand-danger;
  border-color: $brand-danger;
}

%style-danger {
  @include style-danger;
}

@mixin style-danger-inverted {
  color: $brand-danger;
  background-color: transparent;
  border-color: $brand-danger;
}

%style-danger-inverted {
  @include style-danger-inverted;
}

@mixin style-warning {
  color: $brand-white;
  background-color: $brand-warning;
  border-color: $brand-warning;
}

%style-warning {
  @include style-warning;
}

@mixin style-warning-inverted {
  color: $brand-warning;
  background-color: transparent;
  border-color: $brand-warning;
}

%style-warning-inverted {
  @include style-warning-inverted;
}

@mixin style-success {
  color: $brand-white;
  background-color: $brand-success;
  border-color: $brand-success;
}

%style-success {
  @include style-success;
}

@mixin style-success-inverted {
  color: $brand-success;
  background-color: transparent;
  border-color: $brand-success;
}

%style-success-inverted {
  @include style-success-inverted;
}

@mixin style-inactive {
  color: $brand-white;
  background-color: $gray-primary;
  border-color: $gray-primary;
}

%style-inactive {
  @include style-inactive;
}

@mixin style-inactive-inverted {
  color: $gray-primary;
  background-color: $brand-white;
  border-color: $gray-primary;
}

%style-inactive-inverted {
  @include style-inactive-inverted;
}

@mixin style-disabled {
  color: $brand-white;
  background-color: $gray-primary;
  border-color: $gray-primary;
}

%style-disabled {
  @include style-disabled;
}

@mixin style-white {
  color: $brand-black;
  background-color: $brand-white;
  border-color: $brand-white;
}

%style-white {
  @include style-white;
}

@mixin style-black {
  color: $brand-white;
  background-color: $brand-black;
  border-color: $brand-black;
}

%style-black {
  @include style-black;
}

@mixin style-black-inverted {
  color: $brand-black;
  background-color: transparent;
  border-color: $brand-black;
}

%style-black-inverted {
  @include style-black-inverted;
}

@mixin style-dark-gray {
  color: $brand-white;
  background-color: $gray-dark;
  border-color: $gray-dark;
}

%style-dark-gray {
  @include style-dark-gray;
}

@mixin style-dark-gray-darken {
  color: $brand-white;
  background-color: darken($gray-dark, $nsx-hover-darken);
  border-color: darken($gray-dark, $nsx-hover-darken);
}

%style-dark-gray-darken {
  @include style-dark-gray-darken;
}

@mixin style-gray-transparent {
  color: $gray-primary;
  background-color: transparent;
  border-color: transparent;
}

%style-gray-transparent {
  @include style-gray-transparent;
}

@mixin style-purple {
  color: $brand-white;
  background-color: $brand-purple;
  border-color: $brand-purple;
}

%style-purple {
  @include style-purple;
}