.nsx-icon-with-text {
  // We can remove the font-size hack when we run our HTML
  // through minification.
  font-size: 0;

  &__icon {
    font-size: 16px;
  }

  &__text {
    margin-left: 12px;
    font-size: 16px;
  }

  &--small {
    .nsx-icon-with-text__icon {
      font-size: 14px;
    }

    .nsx-icon-with-text__text {
      margin-left: 12px;
      font-size: 14px;
    }
  }
}
