.nsx-select-option {
  display: flex;
  .current-filter-label & { align-items: center; }

  &__icon-col {
    flex-basis: 32px;
    flex-shrink: 0;
    display: flex;

    .current-filter-label & {
      display: inline-flex;
      align-items: center;
    }
  }
  &__text-col {
    align-items: center;
  }
  &__primary-text {
    display: block;
    font-size: $nsx-font-size-16;
    flex: 1 0 100%;

    .current-filter-label & { font-size: $nsx-font-size-14; }
  }
  &__secondary-text {
    .current-filter-label & { display: none; }
    display: block;
    flex: 1 0 100%;
    color: $secondary-text-color;
    font-size: $nsx-font-size-12;
    line-height: 16px;
    white-space: normal;
    min-height: 32px;
    margin-bottom: -4px;
  }
}
