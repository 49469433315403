.nsx-text-with-badges {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 74px;
    flex: 1 1 auto;
  }

  &__badges {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    margin-left: 4px;
    padding-top: 3px;
  }

  &__badge {
    margin: 1px 3px 3px 0;
  }

  &__dropdown {
    margin-left: 6px;
  }
}