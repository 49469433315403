.nsx-permission-title-row {
  @extend .clearfix;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  @extend %style-dark-gray;
  font-size: $nsx-font-size-18;

  &__main {
    border-right: solid 2px $brand-white;
    padding: 10px 0;
    flex-grow: 1;
    flex-basis: 100%;
  }

  &__column {
    position: relative;
    border-left: solid 2px $brand-white;
    text-align: center;
    flex-basis: $nsx-permission-column-width;
    flex-grow: 1;
    padding: 10px;

    &--first {
      border-left: 0;
    }
  }
}