.nsx-dashboard-chart {
  padding: 0 $dashboard-chart-padding $dashboard-chart-padding;

  .c3-ygrid {
    stroke: $gray-primary-medium;
  }

  .c3-axis .tick {
    fill: $gray-dark;
  }

  .c3-axis .tick line,
  .c3-axis.c3-axis-x path {
    stroke: $gray-primary-medium;
  }

  .c3-axis.c3-axis-y path,
  .c3-axis.c3-axis-y line {
    stroke: transparent;
  }

  .c3-xgrid-line line {
    stroke: $gray-primary-medium;
    stroke-dasharray: 3 3;
  }

  .c3-line {
    stroke-width: 2px;
  }

  .c3-shape.c3-circle {
    r: 3.5;
  }

  svg {
    overflow: initial !important;
  }
}

.nsx-dashboard-chart.nsx-dashboard-chart--no-spacing {
  padding: 0;
}

.nsx-dashboard-chart.nsx-dashboard-chart--inverted {
  .c3-axis .tick line,
  .c3-axis.c3-axis-x path,
  .c3-axis.c3-axis-x line {
    stroke: transparent;
  }

  .c3-axis.c3-axis-y path {
    stroke: $gray-primary-medium;
  }
}
