.nsx-onboarding-info {
  position: absolute;
  margin: $nsx-gutter 0;
  width: 170px;
}

.nsx-onboarding-info--down {
  .nsx-onboarding-info__arrow {
    transform: rotate(180deg);
  }
}

.nsx-onboarding-info--add-relation {
  bottom: 55px;
  right: -185px;

  .nsx-onboarding-info__arrow {
    margin-top: $nsx-gutter;
    margin-left: 10px;
    margin-right: 0;
  }
}

.nsx-onboarding-info--avatar {
  top: $avatar-size-xl + $nsx-gutter;
  left: 0;
  width: 100%;
}

  .nsx-onboarding-info__arrow {
    position: relative;
    margin: 0 auto;

    & + .nsx-onboarding-info__text {
      margin-top: $nsx-gutter;
    }
  }

  .nsx-onboarding-info__text {
    @extend %style-primary;
    padding: 10px;
    font-size: 14px;
    border-radius: $nsx-base-border-radius;
    text-align: left;

    & + .nsx-onboarding-info__arrow {
      margin-top: $nsx-gutter;
    }
  }
