html,
body {
  font-family: $font-family-sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
  outline: none;
}

img {
  vertical-align: middle;
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
