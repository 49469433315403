.nsx-icon-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: $nsx-icon-border-width;
  border-radius: 50%;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

@mixin nsx-icon-btn-size($size) {
  $line-height: $size  - ($nsx-icon-border-width * 2);
  width: $size;
  height: $size;
  line-height: $line-height;
  font-size: $size * $nsx-icon-font-size-scale;

  #{$all-icon-selectors} {
    line-height: $line-height;
  }
}

.nsx-icon-btn--20 { @include nsx-icon-btn-size($nsx-icon-size-20); }
.nsx-icon-btn--25 { @include nsx-icon-btn-size($nsx-icon-size-25); }
.nsx-icon-btn--32 { @include nsx-icon-btn-size($nsx-icon-size-32); }
.nsx-icon-btn--35 { @include nsx-icon-btn-size($nsx-icon-size-35); }
.nsx-icon-btn--42 { @include nsx-icon-btn-size($nsx-icon-size-42); }
.nsx-icon-btn--55 { @include nsx-icon-btn-size(55px) }
.nsx-icon-btn--60 { @include nsx-icon-btn-size($nsx-icon-size-60); }

.nsx-icon-btn.nsx-icon-btn--icon-size-6 { font-size: 6px; }
.nsx-icon-btn.nsx-icon-btn--icon-size-10 { font-size: 10px; }
.nsx-icon-btn.nsx-icon-btn--icon-size-16 { font-size: 16px; }
.nsx-icon-btn.nsx-icon-btn--icon-size-20 { font-size: 20px; }
.nsx-icon-btn.nsx-icon-btn--icon-size-25 { font-size: 25px; }
.nsx-icon-btn.nsx-icon-btn--icon-size-32 { font-size: 32px; }

//
// colours
//
.nsx-icon-btn--primary,
.nsx-icon-btn--primary:focus {
  @extend %style-primary;
  color: $primary-text-color;
  width: 25px;
  height: 25px;

  &.active,
  &:hover {
    @extend %style-primary-inverted;
    background-color: $brand-black;
    color: $brand-white;
    border-color: $brand-black;

    i.icon {
      color: $brand-white;
    }
  }
}

.nsx-icon-btn--primary-solid {
  @extend %style-primary;
  color: $primary-text-color;

  &.active,
  &:hover {
    @extend %style-primary;
  }
}

.nsx-icon-btn--shadowed {
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0 , 0.2);
}

.nsx-icon-btn--primary-hover-shadowed {
  &:hover {
    box-shadow: 0 0 0 8px rgba($brand-primary, 0.1);
  }
}

.nsx-icon-btn--inactive-hover-shadowed {
  &:hover {
    box-shadow: 0 0 0 8px rgba($brand-black, 0.1);
  }
}

.nsx-icon-btn--inactive,
.nsx-icon-btn--inactive:focus {
  @extend %style-inactive;

  &.active,
  &:hover {
    @extend %style-inactive-inverted;
  }
}

.nsx-icon-btn--inactive-solid,
.nsx-icon-btn--inactive-solid:focus {
  @extend %style-inactive;

  &.active,
  &:hover {
    @extend %style-inactive;
  }
}

.nsx-icon-btn--inactive-inverted-solid,
.nsx-icon-btn--inactive-inverted-solid:focus {
  @extend %style-inactive-inverted;

  &.active,
  &:hover {
    @extend %style-inactive-inverted;
  }
}

.nsx-icon-btn--danger,
.nsx-icon-btn--danger:focus {
  @extend %style-danger;

  &.active,
  &:hover {
    @extend %style-danger-inverted;
    background-color: $brand-black;
    color: $brand-white;
    border-color: $brand-black;
  }
}

.nsx-icon-btn--danger-solid {
  @extend %style-danger;
}

.nsx-icon-btn--warning,
.nsx-icon-btn--warning:focus {
  @extend %style-warning;

  &.active,
  &:hover {
    @extend %style-warning-inverted;
  }
}

.nsx-icon-btn--warning-solid {
  @extend %style-warning;
}

.nsx-icon-btn--success,
.nsx-icon-btn--success:focus {
  @extend %style-success;

  &.active,
  &:hover {
    @extend %style-success-inverted;
  }
}

.nsx-icon-btn--success-solid {
  @extend %style-success;

  &.active,
  &:hover {
    @extend %style-success;
  }

}

.nsx-icon-btn--success-inverted {
    @extend %style-success-inverted;

    &.active,
    &:hover {
      @extend %style-success;
    }
}

.nsx-icon-btn--success-inverted-solid {
    @extend %style-success-inverted;

    &.active,
    &:hover {
      @extend %style-success-inverted;
    }

}

.nsx-icon-btn--action,
.nsx-icon-btn--action:focus {
  @extend %style-gray-transparent;

  &.active,
  &:hover {
    @extend %style-primary;
  }
}

.nsx-icon-btn--black {
  @extend %style-black;
}

.nsx-icon-btn--black-solid {
  @extend %style-black;

  &.active,
  &:hover {
    @extend %style-black;
  }
}

.nsx-icon-btn--black-inverted {
    @extend %style-black-inverted;

    &.active,
    &:hover {
      @extend %style-black;
    }
}

.nsx-icon-btn--black-inverted-solid {
    @extend %style-black-inverted;

    &.active,
    &:hover {
      @extend %style-black-inverted;
    }

}

.disabled-delete {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.roles-button {
  color: $brand-black;
}

.roles-button.disabled-delete {
  color: $gray-dark;
  opacity: 1;

  &.active,
    &:hover {
      color: $brand-white;
    }
}
