.nsx-accordion {}

.nsx-accordion-header {
  width: 100%;
  background-color: $gray-bg;
  height: 44px;
  line-height: 44px;
  padding-left: 65px;
  position: relative;
  cursor: pointer;
  margin-bottom: $baseline;
  font-size: $nsx-font-size-16;

  &:hover {
    background-color: darken($gray-bg, $nsx-hover-darken);
  }
}

.nsx-accordion-header.nsx-accordion-header--dark {
  @extend %style-dark-gray;

  &:hover {
    @extend %style-dark-gray-darken;
  }
}

.nsx-accordion-header.nsx-accordion-header--primary {
  @extend %style-primary;

  &:hover {
    @extend %style-primary-darken;
  }
}

.nsx-accordion-header__icon {
  position: absolute;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  right: calc($nsx-gutter / 2);
  top: 50%;
  display: block;
  transform: translate(0, -50%);
}
