.nsx-permission-row {
  @extend .clearfix;
  display: flex;
  align-items: center;

  &__main {
    flex-grow: 1;
    flex-basis: 100%;
    overflow: hidden;
  }

  &__secondary-text {
    font-size: 12px;
    color: $gray-dark;
    padding-top: 5px;
    font-weight: 300;
  }

  &__column {
    $column: &;
    position: relative;
    padding: 0 10px;
    border-left: solid 2px $gray-bg;
    min-height: 80px;
    text-align: center;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: $nsx-permission-column-width;
    display: flex;
    align-items: center;
    justify-content: center;

    &--first {
      border-left: 0;
    }

    &--editable {
      cursor: pointer;
    }

    &-edit {
      display: none;
      position: absolute;
      top: 20px;
      right: 10px;
      color: $gray-primary;

      #{$column}:hover & {
        display: block;
      }
    }
  }

  &__badge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
