$nsx-intro-arrow-width: 30px;

.intro-arrow {
    position: absolute;
    width: $nsx-intro-arrow-width;
}

.intro-arrow--onboarding_step-1 {
  display: none;
}
.intro-arrow--onboarding_step-2 {
  top: $onboarding-nav-height + $navbar-height + $nsx-gutter;
  left: 60px;
}
.intro-arrow--onboarding_step-3 {
  top: $onboarding-nav-height + $navbar-height + $nsx-gutter;
  right: 100px;
}
.intro-arrow--onboarding_step-4 {
  position: fixed;
  z-index: 9999;
  top: 120px;
  left: 14px;
}
.intro-arrow--onboarding_step-5 {
  position: fixed;
  bottom: 100px;
  right: 34px;
  transform: rotate(180deg);
}
.intro-arrow--onboarding_step-6 {
  top: 270px;
  right: calc(((100% - 838px) / 2) - 60px); // 838 = panel width
}

.intro-arrow__dot {
  width: 6px;
  height: 6px;
  margin-left: 12px;
  background-color: $brand-primary;
  margin-top: 14px;
  opacity: 0;
  border-radius: 50%;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;

  &:nth-child(2) {
      animation-name: intro-arrow-dot-5;
  }
  &:nth-child(3) {
      animation-name: intro-arrow-dot-4;
  }
  &:nth-child(4) {
      animation-name: intro-arrow-dot-3;
  }
  &:nth-child(5) {
      animation-name: intro-arrow-dot-2;
  }
  &:nth-child(6) {
      animation-name: intro-arrow-dot-1;
  }
}

.intro-arrow__head {
  width: $nsx-intro-arrow-width;
  height: 10px;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-name: intro-arrow-dot-6;
  position: relative;

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: $nsx-intro-arrow-width;
    height: 4px;
    background-color: $brand-primary;
  }
  &:before {
    left: calc($nsx-intro-arrow-width / 2);
    transform-origin: top left;
    transform: rotate(45deg);
  }
  &:after {
    right: calc($nsx-intro-arrow-width / 2);
    transform-origin: top right;
    transform: rotate(-45deg);
  }
}

@mixin intro-arrow-dot-keyframes($offset: 0, $distance: 6) {
  0% { opacity: 0; }
  #{$offset}% { opacity: 0; }
  #{$offset + $distance}% { opacity: 1; }
  100% { opacity: 1; }
}
@keyframes intro-arrow-dot-1 {
  @include intro-arrow-dot-keyframes(5);
}
@keyframes intro-arrow-dot-2 {
    @include intro-arrow-dot-keyframes(11);
}
@keyframes intro-arrow-dot-3 {
    @include intro-arrow-dot-keyframes(17);
}
@keyframes intro-arrow-dot-4 {
    @include intro-arrow-dot-keyframes(23);
}
@keyframes intro-arrow-dot-5 {
    @include intro-arrow-dot-keyframes(29);
}
@keyframes intro-arrow-dot-6 {
    @include intro-arrow-dot-keyframes(35);
}
