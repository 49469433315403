.nsx-wait-screen {
  min-width: 200px;
  min-height: 100%;
  width: 50%;
  background-image: url('#{$nsx-image-path}/wait_screen_img.svg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  display: table-cell;
  vertical-align:middle;
}
