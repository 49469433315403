.nsx-handover-card {
  margin-bottom: 10px;

  &__main {
    padding: 12px 12px 26px 12px;
  }

  &__person {
    display: flex;
    overflow: hidden;
  }

  &__person-text {
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__address {
    padding-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__empty-text {
    padding-top: 15px;
    color: $gray-dark;
    font-size: $nsx-font-size-16;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .nsx-list-card__inner {
    overflow: visible !important;
  }
}
