.nsx-search-dropdown__link {
  font-size: 30px;
  color: $primary-text-color;
  position: relative;
  top: 10px;
  display: inline-block;
  padding-right: 20px;

  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}

.nsx-search-dropdown__caret {
  font-size: 20px;
  position: relative;
  top: -6px;
  left: -5px;
}

.nsx-search-dropdown__popup {
  width: 330px;
  padding: $nsx-gutter $baseline;
}

.nsx-search-dropdown__title {
  font-size: $nsx-font-size-18;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.nsx-search-dropdown__row {
  margin-bottom: $baseline;
}
