$nsx-btn-width: 140px;
$nsx-btn-padding: 30px;

.nsx-btn {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  line-height: $nsx-form-input-height;
  height: $nsx-form-input-height;
  padding: 0 $nsx-btn-padding;
  min-width: $nsx-btn-width;
  font-size: $nsx-font-size-18;
  border-style: solid;
  border-width: 1px;
  border-radius: $nsx-base-border-radius;
  text-decoration: none;
  vertical-align: middle;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.nsx-btn--primary {
    @extend %style-primary;

    &.active,
    &:hover {
        @extend %style-primary-inverted;
        background-color: $brand-black !important;
        color: $brand-white !important;
        border-color: $brand-black !important;
    }
    &:focus {
      @extend %style-primary;
    }
}

.nsx-btn--primary-inverted {
    @extend %style-primary-inverted;

    &.active,
    &:hover {
        @extend %style-primary;
    }
    &:focus {
      @extend %style-primary-inverted;
    }
}

.nsx-btn--danger {
    @extend %style-danger;

    &.active,
    &:hover {
        @extend %style-danger-inverted;
    }
    &:focus {
      @extend %style-danger;
    }
}

.nsx-btn--danger-inverted {
    @extend %style-danger-inverted;

    &.active,
    &:hover {
        @extend %style-danger;
    }
    &:focus {
      @extend %style-danger-inverted;
    }
}

.nsx-btn--unstyled {
  border-color: $brand-black;
  background-color: $brand-white;
  color: $brand-black;

  &:hover {
    background-color: $brand-black;
    color: $brand-white;
  }
  &:focus {
    color: $gray-dark;
  }
}

.nsx-btn[disabled] {
    @extend %style-disabled;

    &.active,
    &:hover {
        @extend %style-disabled;
    }
}

.nsx-btn--disabled {
  border-color: transparent;
  color: $gray-dark;

  &.active {
    border: 1px solid $gray-primary;
  }
}

.nsx-btn--grey {
  @extend .nsx-btn;
  color: $brand-white;
  background: $gray-dark;
  border-color: $gray-dark;
  border-radius: 4px;
  float: right;
}
