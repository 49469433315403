.nsx-input-select,
.nsx-input,
input.nsx-input,
textarea.nsx-textarea {
  border: $nsx-base-border;
  border-radius: $nsx-base-border-radius;
  box-shadow: none;
}

.nsx-input,
input.nsx-input,
textarea.nsx-textarea {
  padding: 15px 20px;
}

.nsx-input,
input.nsx-input,
.nsx-input-select {
  height: $nsx-form-input-height;
}

.nsx-input-select {
  cursor: pointer;
  @extend %nsx-select-style;
  line-height: $nsx-form-input-height;
  padding-top: 0;
  padding-left: 15px;
  padding-bottom: 0;
  &.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.nsx-input.missing-value {
  border-color: $alert-danger-bg;
}

.nsx-textarea.missing-value {
  border-color: $alert-danger-bg;
}

.nsx-input-careplan-review.required {
  position: relative;

  &:before {
    content: "*";
    color: $brand-primary;
    position: absolute;
    font-size: 20px;
    right: 5px;
    top: -25px;

    @media screen and (max-width: 900px) {
      position: static;
    }
  }
}

