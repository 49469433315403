list-panel-item-client-type {
  .nsx-list-panel-item-service__media {
    text-align: right;
    .disabled-delete {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }

  .nsx-list-panel-item-service__text {
    float: left;
    padding-left: 24px;
    width: calc(100% - 201px);
    input[type=text] {
      margin-top: 6px;
      margin-bottom: 6px;
    }
    .alert-block {
      height: 16px;
    }
  }

  .nsx-list-panel-item-service__actions {
    width: 140px;
    button[type=submit] {
      width: 120px;
      height: 40px;
      margin-bottom: 12px;
    }
  }

  .disabled-action {
    opacity: 0.5;
    cursor: not-allowed !important;
    .checkbox-label {
      cursor: not-allowed !important;
    }
  }
}

.client-type-assign-modal {
  .nsx-new-modal__icon {
    flex-basis: 20px;
  }
  .nsx-new-modal__actions {
    flex-basis: auto;
    margin-left: 20px;
  }
}
