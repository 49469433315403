.nsx-permissions-header-cell {
  position: relative;
}

.nsx-permissions-header-cell__title {
  font-size: $nsx-font-size-20;
  color: $primary-text-color;
  @extend %text-ellipsis;
}

.nsx-permissions-header-cell__subtitle {
  font-size: $nsx-font-size-14;
  color: $gray-dark;
  @extend %text-ellipsis;
}

.nsx-permissions-header-cell__edit-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.nsx-permissions-header-cell__label {
  position: absolute;
  bottom: 15px;
  left: 10px;
}
