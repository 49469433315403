.nsx-sidebar-item {
  font-size: $nsx-font-size-16;
  min-height: $sidebar-width-minimized;
  display: block;
  position: relative;

  &--level-2 {
    padding-left: $sidebar-width-minimized;
    padding-right: 20px;
    min-height: $sidebar-width-minimized - 10px;
    height: $sidebar-width-minimized - 10px;
    font-size: $nsx-font-size-14;
    width: $sidebar-width;
  }

  &.active,
  &:hover {
    color: $brand-white;
    cursor: pointer;
  }

  &__link {
    color: $sidebar-link-color;
    display: flex;
    align-items: center;
    padding: 0;
    padding-right: 50px;
    position: relative;
    width: $sidebar-width;

    &:hover,
    &:focus,
    &--is-active {
      color: $sidebar-link-hover-color;
    }
  }

  &__icon-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: $sidebar-width-minimized;
    height: $sidebar-width-minimized;
  }

  &__label {
    @extend .text-overflow-ellipsis;
    max-width: 110px;
  }

  &__toggle {
    line-height: 0;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);

    &:after {
      font-family: 'nourish-icons';
      line-height: 1;
      content: "\e621";
      color: $secondary-text-color;
    }

    &--expanded {
      &:after {
        content: "\e906";
      }
    }
  }
}

.nsx-sidebar-nested {
  width:  $sidebar-width - 40px;
  padding-right: 0px;
}
