.nsx-count-icon {
  position: relative;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;

  &__icon {
    font-size: 22px;
  }

  &__count {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
