//** LIST ENTRY **//
@mixin nsx-list-entry-builder($left-col-size, $right-col-size) {
  .left-col {
    width: $left-col-size;
  }

  .main-col {
    width: calc(100% - #{$left-col-size + $right-col-size + 1px});
  }

  .right-col {
    text-align: right;
    margin-left: auto;
    width: $right-col-size;
  }
}

@function _group-selectors($selector_array, $pseudo: null) {
  $list: ();

  @each $selector in $selector_array {
    $selector: unquote($selector);
    $selector: if($pseudo, $selector + ":" + $pseudo, $selector);
    $list: append($list, $selector, comma);
  }

  @return $list;
}
