.nsx-onboarding-section {
  position: relative;

  .single-panel-header {
    &.active + .onboarding-list{
      overflow: visible;
    }
  }




}

.nsx-onboarding-section--with-nav {
  padding-top: ($onboarding-nav-height + $navbar-height);

  .maintenance-banner-show & {
    padding-top:( $onboarding-nav-height + $navbar-height + $maintenance-banner-height);
  }
}
