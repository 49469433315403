.resident-dashboard-container {
    position: absolute;
    height: calc(100% - 48px);
    width: calc(100% - 48px);
    border-radius: 4px;
    background: white;
    display: grid;
    grid-template-columns: minmax(22%, 33%) minmax(67%, 78%);
    overflow-y: scroll;

    @media screen and (max-width: $screen-sm) {
        height: calc(100% - 24px);
        width: calc(100% - 24px);
        margin: 12px;
        display: block;
    }
}

.resident-dashboard-left {
    grid-column: 1 / 2;
    background: $gray-bg;
    height: 100%;
    border-top-left-radius:  4px;
    border-bottom-left-radius: 4px;
    overflow-y: visible;

    @media screen and (max-width: $screen-sm) {
        display: none;
    }
}

.resident-dashboard-right {
    position: relative;
    height: 100%;
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
}

.resident-dashboard-right::before,
.resident-dashboard-right::after  {
    content: " ";
    display: none;
    position: absolute;
    height: calc(100% - 125px);
    background: $gray-primary-medium;
    width: 1px;
    top: 100px;
}

@media screen and (max-width: 1620px) {
    .resident-dashboard-right::before {
        display: block;
        left: 50%;
    }
}

@media screen and (min-width: 1621px) {

    .resident-dashboard-right::before {
        display: block;
        left: 33.33%;
    }

    .resident-dashboard-right::after {
        display: block;
        left: 66.66%;
    }
}

@media screen and (max-width: 1000px) {
    .resident-dashboard-right::before {
        display: none;
    }

    .resident-dashboard-right::after {
        display: none;
    }

    .resident-dashboard-right::after {
        display: block;
        left: 32px;
        content: " ";
        position: absolute;
        height: 1px;
        background: $gray-primary-medium;
        width: calc(100% - 64px);
        top: 100%;
    }
}

.resident-dashboard-title {
    padding: 40px 0 32px 32px;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;

    @media screen and (max-width: $screen-sm) {
        padding: 32px 0 12px 32px;
    }
}

.resident-dashboard-sections {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: 100%;
    margin: 0 auto;

    display: grid;
    @media screen and (max-width: $screen-sm) {
        display: flex;
        flex-direction: column;
    }
}

.resident-dashboard-section {
    padding: 0 24px 24px 32px;
    padding-right: 1px;
    width: 33.33%;
    min-width: 33.33%;
    box-sizing: border-box;
    display: flex;

    @media screen and (max-width: 1620px) {
        width: 50%;
        min-width: 50%;
    }

    @media screen and (max-width: 1000px) {
        width: 100%;
        min-width: 100%;
        margin-top: 24px;
    }
}

.resident-dashboard-avatar {
    border-top-left-radius: 4px;
    height: auto;
    width: 100%;
    padding-top: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: space;
}

.resident-dashboard-info {
    height: auto;
    width: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.resident-dashboard-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: 5px;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.resident-dashboard-name,
.resident-dashboard-age {
    margin: 5px 0;
}

.resident-dashboard-age {
    text-align: end;
}

.resident-dashboard-divider {
    height: 1px;
    width: 100%;
    background: $brand-black;
}

.resident-dashboard-room {
    margin-top: 10px;
}

.resident-dashboard-bio {
    margin-top: 20px;
    width: min-content;
    min-width: 100%;
    margin-bottom: 20px;
    .biography {
        white-space: pre-line;
    }
}

.resident-dashboard-section-icon {
    margin-right: 10px;
}

.resident-dashboard-section-text {
    display: block;
    min-width: calc(100% - 75px);
}

.resident-dashboard-section-label-no-badge {
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 20px;
    color: $brand-black;
    text-transform: uppercase;
}

.resident-dashboard-section-label-badge {
    color: white;
    font-size: 12px;
    font-weight: 700;
    border-radius: 2px;
    height: 20px;
    padding: 3px 6px;
    width: min-content;
    text-transform: uppercase;
    margin-bottom: 4px;
}

.resident-dashboard-section-value {
    margin-top: 8px;
}

.resident-dashboard-section-value-link {
  cursor: pointer;
  text-decoration: underline;
}

.resident-dashboard-updated-ago {
  display: flex;
  background-color: #ffe8e9;
  border-radius: 15px;
  padding: 3px 10px 3px 3px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: max-content;
  color: $brand-danger;
  font-size: small;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  align-items: center;

  &--main-text {
    font-weight: 550;
    padding-top: 2px;
    padding-left: 5px;
  }

  &--time {
    font-weight: 300;
    padding-left: 5px;
    margin-top: 2px;
  }

  svg {
    overflow: visible;
  }

  @media screen and (max-width: 1350px) {
    .resident-dashboard-updated-ago--time {
        display: none;
    }
  }
}

.emergency {
    height: 70px;
    width: 100%;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 15px;
    border: $gray-primary-medium solid 1px;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    background: white;

    > i {
        margin-right: 10px;
    }
}

.emergency:hover {
    cursor: pointer;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
}

.ambulance-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #FF6169;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    i {
        margin-right: 2px;
    }

}

.emergency-text {
    width: 80px;
    margin-left: 20px;
    flex-grow: 1;
}

.resident-dashboard-right::-webkit-scrollbar-thumb,
.resident-dashboard-left::-webkit-scrollbar-thumb {
    background: $gray-primary-medium;
    border: 4px solid transparent;
    border-radius: 4px;
    background-clip: padding-box;
}

.resident-dashboard-right::-webkit-scrollbar,
.resident-dashboard-left::-webkit-scrollbar {
    width: 16px;
}

.resident-dashboard-reports {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &--title {
        font-weight: $nsx-font-weight-bold;
        padding-right: 10px;
    }

    & .qr-code-link {
        width: 310px;
        overflow: hidden;
        white-space: normal;
    }
}

.resident-dashboard-quick-notes {
    &--title {
        font-weight: $nsx-font-weight-bold;
        margin-bottom: 5px;
    }
}
