$nsx-date-input-width: 180px;

.nsx-card-form-group {
  @include nsx-list-entry-builder($nsx-card-left-col-width, $nsx-card-actions-col-width);
  @extend %flex-wrapper-center;
  width: 100%;
  font-size: $nsx-font-size-14;
  flex-wrap: wrap;

  margin-bottom: $baseline;

  &:last-child {
    margin-bottom: 0;
  }

  .left-col {
    align-self: flex-start;
    text-align: right;
    padding-right: $baseline;
  }

  select {
    max-width: 340px;
  }

  select,
  textarea,
  input[type="text"] {
    min-height: $nsx-form-input-height;
    color: $primary-text-color;
  }

  textarea {
    font-size: $nsx-font-size-14;
  }

  textarea {
    min-height: 64px;
  }
}

.nsx-card-form-group__content {
  width: 100%;
}

.nsx-card-date-time {
  width: 100%;
}

.nsx-card-date-time__date, .nsx-card-date-time__date {
  margin-right: 16px;
}

.nsx-card-date-time__icon,
.nsx-card-date-time__input {
  margin-right: 15px;
}

.nsx-card-date-input,
input.nsx-card-date-input {
  display: inline;
  max-width: $nsx-date-input-width;
}
