.nsx-tr {
  min-width: 100%;
  display: flex;
  background-color: $gray-light-new;
  border-bottom: $nsx-base-border;

  &:nth-child(even) {
    background-color: $gray-light-2-new;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.nsx-td {
  width: $nsx-table-cell-width;
  height: $nsx-table-cell-height;
  border-right: $nsx-base-border;
  flex-shrink: 0;

  &:last-child {
    border-right: 0;
  }
}

.nsx-th {
  width: $nsx-table-cell-width;
  height: $nsx-table-header-height;
  border-right: $nsx-base-border;
  background-color: $brand-white;
  color: $primary-text-color;
  flex-shrink: 0;

  &:last-child {
    border-right: 0;
  }
}

.nsx-scrolling-table {
  max-width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  border-bottom: $nsx-base-border;
  border-right: $nsx-base-border;

  &:before {
    content: "";
    width: $nsx-table-sidebar-width;
    height: $nsx-table-header-height + 2px;
    background: $gray-bg;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
}

.nsx-scrolling-table__header {
  float: left;
  overflow: visible;
  border-left: $nsx-base-border;
  margin-left: $nsx-table-sidebar-width;
  background-color: $brand-white;
  border-left: $nsx-base-border;
  border-bottom: $nsx-base-border;
  border-top: $nsx-base-border;
  position: relative;

  &:before {
    content: "";
    width: 1px;
    height: 100%;
    background-color: $nsx-border-color;
    position: absolute;
    top: 0;
    left: -1px;
    z-index: 3;
  }
}

.nsx-scrolling-table__header-table {
  display: flex;

  .nsx-th,
  .nsx-td {
    flex-shrink: 0;
  }
}

.nsx-scrolling-table__sidebar {
  position: relative;
  width: $nsx-table-sidebar-width;
  height: calc(100% - #{$nsx-table-header-height});
  float: left;
  clear: left;

  .nsx-td {
    width: $nsx-table-sidebar-width;
    min-width: $nsx-table-sidebar-width;
    border-left: $nsx-base-border;
  }
}

.nsx-scrolling-table__body {
  float: left;
  width: calc(100% - #{$nsx-table-sidebar-width});
  height: calc(100% - #{$nsx-table-header-height + $nsx-table-axis-label-height + 2px});
  overflow: auto;
  border-left: $nsx-base-border;

  &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
      height: 6px;
  }
  &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $secondary-text-color;
      -webkit-box-shadow: 0 0 1px $gray-bg;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: $gray-bg;
  }
}

.nsx-scrolling-table__flex-container {
  display: inline-block;
}
