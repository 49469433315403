.nsx-new-modal {
  padding: 10px 0;

  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 10px;
    min-height: 27px;
  }

  &__icon {
    display: flex;
    flex-basis: 90px;
    flex-shrink: 0;
    justify-content: flex-end;
    padding-right: 20px;
  }

  &__label {
    flex-basis: 100%;
  }

  &__actions {
    flex-basis: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }

  &__content {
    padding: 20px;
  }

  &__action {
    flex-basis: 100%;
  }

  &__action nsx-checkbox {
    margin-left: 20px;
  }

  &__submenu-btn {
    display: flex;
    flex-shrink: 0;
    flex-basis: 25px;
    padding-left: 10px;
  }


  &__submenu {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }

  &__float-above {
    z-index: 99999;
  }
}

.nsx-role-service-privilege {
  display: flex;
  align-items: center;
  padding: 5px 0;

  &__radio-container {
    display: flex;
    align-items: center;
    width: 230px;
    font-size: 14px;
  }

  &__radio-cell {
    width: 33.33%;
    text-align: center;

    label { margin-bottom: 0; }
    input { display: none; }
  }
}
