.nsx-badge {
  color: $brand-black;
  background-color: $gray-medium-new;

  font-size: $nsx-font-size-16;
  line-height: 1.5;
  overflow: hidden;
  border-radius: 2px;
  padding: 0 5px;
  display: inline-block;
  text-align: center;

  &--primary {
    background-color: $brand-primary;
    color: $brand-white;
  }

  &--black {
    @include style-black;
  }

  &--danger {
    background-color: $brand-danger;
    color: $brand-white;
  }

  &--warning {
    background-color: $brand-warning;
    color: $brand-white;
  }

  &--dark-gray {
    @include style-dark-gray;
  }

  &--small {
    height: 14px;
    font-size: 9px;
    line-height: 9px;
    padding-top: 3px;
    font-weight: 700;
  }

  /** Client Badges **/

  &--allergies, &--allergy {
    background-color: $color-orange;
    color: $brand-black;
  }

  &--respect {
    background-color: $color-lavander;
    color: $brand-black;
  }

  &--abilities { 
    background-color: $color-green;
    color: $brand-black;
  }

  &--diet { 
    background-color: $color-violet;
    color: $brand-white;
  }
}

.nsx-badge-container {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
}
