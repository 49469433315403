.chart-timepicker {
  align-items: center;
  display: flex;
  position: relative;
}

.chart-timepicker__label {
  color: $brand-black;
  margin-left: 8px;
  flex-grow: 1;

  &:hover,
  &:visited,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

.chart-timepicker__button {
  background-color: transparent;
  border: 0;
  padding: 6px 12px 6px 12px;
}

.chart-timepicker__popover {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: none;
  min-width: 300px;
  position: absolute;
  z-index: 3;
}

.chart-timepicker__range-list {
  list-style-type: none;
  margin: 0;
  padding: 5px;
}

.chart-timepicker__range-list-item:not(:last-child) {
  margin-bottom: 5px;
}

.chart-timepicker__range {
  align-items: center;
  background-color: $gray-primary-light;
  border: 0;
  border-radius: 0;
  display: flex;
  height: 40px;
  padding-left: 8px;
  width: 100%;

  &:hover {
    background-color: $gray-primary-medium;
  }
}

.chart-tooltip__bubble {
  -webkit-print-color-adjust: exact;
}
