.nsx-select {
  &__dropdown-menu {
    width: 100%;
  }
}

.nsx-select-info {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid $gray-light-2-new;
}
