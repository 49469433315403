.nsx-rotas-table {
  margin-bottom: 100px;
}

.nsx-rotas-cell {
  position: relative;
  height: 100%;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.nsx-rotas-cell:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 0 0 100px 0;
  z-index: 5;
}

.nsx-rotas-cell--wellstaffed:before {
  background-color: $brand-success;
}

.nsx-rotas-cell--overstaffed:before {
  background-color: $brand-warning;
}

.nsx-rotas-cell--understaffed:before {
  background-color: $brand-danger;
}

.nsx-rotas-cell--not-active:before {
  background-color: rgba($gray-bg, 0.15);
  cursor: not-allowed;
}

.nsx-rotas-cell--empty {
  background-color: $gray-bg;
  background-image: repeating-linear-gradient(45deg, $gray-light-new, $gray-light-new 5px, $gray-medium-new 5px, $gray-medium-new 6px)
}

.nsx-rotas-cell-wrapper {
  height: 100%;
  width: 100%;
}

.nsx-rotas-cell__inner {
  position: relative;
  height: 100%;
  min-height: 110px;
  padding-top: 31px;
}

.nsx-rotas-cell__inner--selected {
  outline: 2px solid $brand-primary;
  outline-offset: -2px;
}

.nsx-rotas-cell__inner--selected-to-copy {
  outline: 2px solid rgba($brand-primary, 0.5);
  outline-offset: -2px;
}

.nsx-rotas-cell__btn-group {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 5px;
  padding-top: 5px;
  @extend .clearfix;
}

.nsx-rotas-cell__btn {
  float: right;
}

.nsx-rotas-cell__dropdown-btn {
  line-height: 19px;
  padding-right: 12px;
}

.nsx-rotas-cell__dropdown-btn--muted {
  color: $gray-medium-new;
}

.nsx-rotas-cell__dropdown-btn-title {
  width: 70%;
  display: inline-block;
}

.nsx-rotas-cell__dropdown-btn--disabled {
  color: $gray-light-new !important;
}

.nsx-rotas-cell__avatar {
  padding: 1px;
}

.nsx-rotas-cell__content-group {
  padding: calc($baseline / 2);
}

.nsx-rotas-cell__placeholder {
  font-style: italic;
  font-weight: 100;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%,-50%);
}
