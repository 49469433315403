.nsx-initials {
  $icon-width: $nsx-icon-size-35;
  width: $icon-width;
  height: $icon-width;
  background-color: $brand-white;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: $icon-width;
  color: $brand-black;
  font-size: 16px;
}
