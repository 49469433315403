.nsx-input-checkbox {
  padding: 15px 0;
  display: block;
  height: $nsx-form-input-height;
  margin: 0;

  &--grouped {
    min-width: 126px;
    display: inline-block;
    white-space: nowrap;
    margin-right: 12px;
  }
}
