.family-portal-state {
  &--suspended {
    color: $gray-dark;
  }

  &--enabled {
    color: $brand-success;
  }

  &--pending {
    color: $brand-warning;
  }
}

.family-portal-role {
  display: flex;
  flex-direction: row;
}

.family-portal-role__label {
  display: flex;
  align-items: center;
}

.family-portal-role__value {
  display: flex;
  align-items: center;
  padding-top: 0 !important;
}