nsx-interaction-list-item {
  // This is necessary for dp-center-on to be able
  // to calculate the height of the interaction correctly.
  display: block;
}

.nsx-interaction-list-item {
  $horizontal-padding: 12px;
  $vertical-padding: 12px;
  $additional-info-vertical-padding: 8px;
  $icon-size: $nsx-interaction-icon-size;
  $icon-padding: $nsx-interaction-icon-padding;

  .nsx-interaction-list__parameters {
    width: 100%;
  }

  .parameter-multi {
    display: flex;
    flex-wrap: wrap;
  }

  .parameter-multi-detail {
    display: flex;
    flex-direction: column;
    margin-left: 3px;
  }

  .parameter-string {
    width: 100%;
    .parameter-coerced-value {
      width: 100%;
      overflow-wrap: break-word;
    }
  }

  .public-note {
    width: 100%;
    overflow-wrap: break-word;
  }

  cursor: pointer;

  &--disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }

  & &__main {
    padding: $vertical-padding $horizontal-padding;
  }

  & &__main-content {
    display: flex;
    align-items: center;
  }

  & &__icon-label {
    @include style-black;

    position: absolute;
    width: 18px;
    height: 18px;
    top: -6px;
    right: -6px;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;

    &--primary {
      @include style-primary;
    }
  }

  & &__labels {
    position: relative;
    z-index: 1;
    padding-top: 10px - $icon-padding;
  }

  & &__label {
    height: 18px;
    width: 42px;
    display: inline-block;
    background-size: 42px 18px;
    background-repeat: no-repeat;

    &--bulk-close { background-image: url('#{$nsx-image-path}icons/group-close-saved.svg'); }
    &--quick-close { background-image: url('#{$nsx-image-path}icons/quick-close-saved.svg'); }
  }

  & &__icon {
    display: block;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;

    width: $icon-size;
    height: $icon-size;
    padding: $icon-padding;

    &--bordered {
      $border-size: 2px;

      border: $border-size solid $gray-primary;
      border-radius: 50%;
      padding: $icon-padding - $border-size;
    }

    &--line-to-bottom {
      &:before {
        position: absolute;
        top: 100%;
        left: calc(50% - 1px);
        width: 2px;

        content: "";
        background-color: $gray-primary;
        height: 100px;
      }
    }

    &--line-to-top {
      &:before {
        position: absolute;
        bottom: 100%;
        left: calc(50% - 1px);
        width: 2px;

        content: "";
        background-color: $gray-primary;
        height: $vertical-padding;
      }
    }
  }

  &__icon-img {
    width: 100%;
    height: 100%;
  }

  & &__title {
    font-size: $nsx-font-size-20;
    line-height: 24px;
    white-space: normal;
  }

  & &__item-right {
    max-width: 77px;
    max-height: 44px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-shrink: 0;
    text-align: right;
    font-size: $nsx-font-size-14;
    color: $gray-dark;
  }

  &__item-right:last-child {
    padding-right: $icon-padding;
  }

  &__item-right + &__item-right {
    padding-left: $horizontal-padding;
  }

  & &__content {
    flex-grow: 1;
    padding: 0 $horizontal-padding;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  & &__content-inner {
    flex-basis: 100%;
    width: 100%
  }

  & &__text {
    width: 100%
  }

  & &__additional-info {
    display: flex;
    padding: $additional-info-vertical-padding $horizontal-padding;
  }


  .parameter-multiple-view-spacing {
    padding-top: 15px;
  }
}

