.nsx-navbar {
  @extend .clearfix;
  @extend %style-primary;
  position: fixed;
  top: 0;
  left: $sidebar-width-minimized;
  right: 0;
  z-index: 150;
  display: flex;

  .maintenance-banner-show & {
    top: $maintenance-banner-height;
  }

  @media screen and (max-width: $mobile-nav-breakpoint) {
    padding-left: $sidebar-width-minimized;
    left: 0;
  }

  &__brand-link {
    float: left;
    line-height: $navbar-height;
    height: $navbar-height;
    width: 88px;
    margin-left: 15px;

    @media screen and (max-width: $mobile-nav-breakpoint) {
      display: none;
    }
  }

  &__brand-logo {
    width: 100%;
    height: auto;
  }

  &__dropdown-container {
    display: none;
    flex-grow: 1;

    @media screen and (max-width: $mobile-nav-breakpoint) {
      display: block;
    }
  }

  &__title {
    color: $brand-white;
    font-size: $font-size-h4;
    line-height: $navbar-height;
    flex-grow: 1;
    text-align: center;
    height: $navbar-height;

    @media screen and (max-width: $mobile-nav-breakpoint) {
      left: $sidebar-width-minimized;
      text-align: left;
      transform: none;
    }
  }

  &__title-text {
    display: inline-flex;
    align-items: center;
  }

  &__subtitle {
    font-size: $nsx-font-size-12;
    position: absolute;
    bottom: -15px;
    left: 0;
  }

  &__warnings {
    line-height: $navbar-height;
    flex-shrink: 0;
    margin-right: 15px;
    color: $brand-white;
  }

  &.hidden {
    display: none;
  }
}
