.nsx-handover-flag {
  font-weight: $nsx-font-weight-regular;
  color: $brand-white;
  background-color: $brand-black;
  padding: 6px 6px 4px 6px;
  display: inline-block;
  position: relative;
  border-radius: 4px 4px 4px 0;
  margin-right: 5px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    border-top: 0;
    border-right: 0;
    border-left: solid 8px $brand-black;
    border-bottom: solid 8px transparent;
  }

  &--large {
    top: 1px;
  }

  &--small {
    top: -1px;
    font-size: 11px;
    padding: 2px 4px;
    border-radius: 2px 2px 2px 0;
    height: 17px;
    line-height: 1.2;

    &:before {
      border-left: solid 4px $brand-black;
      border-bottom: solid 4px transparent;
    }
  }

  &--alarm {
    top: 1px;
  }
}
