.nsx-main-container {
  top: 0;
  left: $sidebar-width-minimized;
  bottom: 0;
  transition: all 200ms linear;
  width: calc(100% - #{$sidebar-width-minimized});

  @media screen and (max-width: $mobile-nav-breakpoint) {
    left: 0;
    width: 100%;
  }
}

.nsx-container {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}
