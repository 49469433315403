.nsx-list-panel-item-search-header {
  display: flex;
  align-items: center;
  min-height: 94px;
  padding: 0 28px;
}

.nsx-list-panel-item-search-header__title {
  width: calc(100% - 261px);
  font-size: 24px;
  font-weight: bold;
}

.nsx-list-panel-item-search-header__search {
  width: 260px;
}

