.nsx-title {
  display: flex;
  align-items: center;

  & &__icon {
    margin-right: 10px;
  }

  & &__text {
    display: inline-block;
  }
}
