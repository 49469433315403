.nsx-dashboard-sidebar {
  padding: 10px;
  @media print {
    display: none;
  }
}

.nsx-dashboard-sidebar__desktop {
  @media (max-width: $dashboard-tablet-breakpoint) {
    display: none;
  }
}

.nsx-dashboard-sidebar__mobile {
  display: none;
  padding: 0 10px;

  @media (max-width: $dashboard-tablet-breakpoint) {
    display: flex;
  }
}
