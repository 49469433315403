// this hack appends a overflow: hidden to the sidebar only on firefox
// we need this since the sidebar top button was disappearing in iphones
// and to fix it  we removed the overflow hidden from the nsx-sidebar class,
// which made firefox style break
@supports (-moz-appearance:none) {
  .nsx-sidebar {
    overflow: hidden;
  }
}

.nsx-sidebar {
  $root: &;
  color: $brand-white;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  max-width: none;
  width: $sidebar-width-minimized;
  white-space: nowrap;
  z-index: 9999;
  transition: all 200ms linear;
  background-color: $brand-black;

  .maintenance-banner-show & {
    top: $maintenance-banner-height;
    height: calc(100% - #{$maintenance-banner-height});
  }


  @media screen and (max-width: $mobile-nav-breakpoint) {
    width: $sidebar-width;
    left: -$sidebar-width;
  }

  &--is-open {
    width: $sidebar-width;
    @media screen and (max-width: $mobile-nav-breakpoint) {
      left: 0;
    }
  }

  &__link {
    color: $brand-white;
  }

  &__content {
    transform: translate3d(0,0,0);
    position: relative;
    z-index: 9996;
    height: calc(100% - #{$sidebar-width-minimized});

    // make sidebar scrollable ONLY when it's open to prevent scrollbars appearing
    #{$root}--is-open & {
      overflow-x: hidden;
      overflow-y: auto;
    }

    // in webkit make it always scrollable and hide the scrollbars
    @media screen and (#{$isWebkit}) {
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    @media screen and (max-width: $mobile-nav-breakpoint) {
      margin-top: $sidebar-width-minimized;
      height: calc(100% - #{$sidebar-width-minimized});
    }
  }

  &__overlay {
    background-color: $brand-black;
    cursor: pointer;
    opacity: 0.25;
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9996;

    &--is-active {
      display: block;
    }
  }
}
