$emergencyInfoBtnSize: 63px;
$downloadEmergencyPackBtnWidth: 208px;
$zIndex: 160;

.nsx-emergency-information-btn {
  z-index: $zIndex;
  position: fixed;
  right: 0px;
  top: $navbar-height + $top-actions-bar-height;
  background-color: $emergency-color;
  width: $emergencyInfoBtnSize;
  height: $emergencyInfoBtnSize;
  text-align: center;
  border-radius: 0 0 0 $emergencyInfoBtnSize;
  -moz-border-radius: 0 0 0 $emergencyInfoBtnSize;
  -webkit-border-radius: 0 0 0 $emergencyInfoBtnSize;
  box-shadow: 0.5px 2px 4px 0px rgba(0,0,0,0.08);
  cursor: pointer;
}

.nsx-emergency-information-btn-icon {
  position: relative;
  top: calc(50% - 22px);
  right: calc(50% - 40px);
  color: white;
  font-size: 30px;
}

.nsx-download-emergency-pack-btn {
   //  Todo tweak top position to displayed under the top bar
  top: 133px;
  z-index: $zIndex;
  height: $sm-round-btn;
  width: $downloadEmergencyPackBtnWidth;
  right: 71px;
  transform: translateY(-50%);
  color: white;
  background-color: $emergency-color;
  border-color: $emergency-color;
  border-radius: 50px;
  box-shadow: 0px 2px 2px 2px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: $brand-danger;
    border-color: $brand-danger;
  }
}

.nsx-download-emergency-pack-download-icon {
  font-size: 22px;
}

.nsx-ambulance-icon-flipped {
  font-size: 45px;
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.emergency-info-container {
  padding-bottom: 17px;
}

.emergency-information-sections {
  height: 85%;
  display: block;
}
.emergency-information-sections-value {
  white-space: pre-line;
}

.emergency-information-section {
  padding: 0 24px 24px 32px;
  display: flex;
  &--archived {
    @extend .emergency-information-section;
    opacity: 0.5;
  }
}

.emergency-information-left {
  height: 100%;
  border-right: $nsx-base-border;
}

.emergency-information-right {
  height: 100%;
  border-left: $nsx-base-border;
  margin-left: -1px;
}

.emergency-info-secondary-text {
  font-size: 14px;
  line-height: 16px;
  color: $gray-dark;
  word-wrap: break-word;
  padding-bottom: 16px;
}

.emergency-info-primary-text {
  @extend .resident-dashboard-section-value;
  word-wrap: break-word;
  font-weight: bold;
}

.emergency-info-paramedic-details {
  @extend .emergency-info-secondary-text;
  padding-bottom: 0;
}

.emergency-info-default-contact-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: $emergency-color;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
}

@media (max-width:767px) {
  .border {
    border: 0px solid !important
  }
}

@media screen and (max-width: $screen-lg-min) {
  .emergency-information-left {
    height: auto !important;
    border-right: none;
  }
  .emergency-information-right{
    height: auto !important;
    border-left: none;
  }

  .nsx-download-emergency-pack-btn {
    width: 55px;
    border-radius: 50%;
  }
}

@media screen and (max-width: $screen-sm) {
  .nsx-emergency-information-btn {
    top: 56px;
    position: fixed;
  }

  .nsx-download-emergency-pack-btn {
    bottom: 20px;
  }
}
