.nsx-panel {
  $padding-hr: 28px;

  border-radius: 6px;
  background-color: $brand-white;
  border: solid 1px $gray-primary-medium;

  & &__header {
    position: relative;
    padding: 0 $padding-hr;
    min-height: 94px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #eae7e3;

    font-size: 24px;
    font-weight: bold;
  }

  $title_width: 250px;

  & &__title {
    width: $title_width;
  }

  & &__header-right-col {
    width: calc(100% - #{$title_width});
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  & &__header-action {
    $width: 150px;
    position: absolute;
    height: 100%;
    top: 0;
    width: $width;
    right: -$width - 30px;

    display: flex;
    align-items: center;
  }

  & &__header-arrow {
    $arrow-height: 18px;
    $arrow-width: 32px;

    display: block;
    height: $arrow-height;
    overflow-y: hidden;
    position: absolute;
    bottom: -$arrow-height;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 1;

    &:before {
      border: $arrow-width transparent solid;
      border-top-color: #edeeef;
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - #{$arrow-width});
      top: - calc($arrow-width / 2);
      width: $arrow-width;
      height: $arrow-height;
      overflow: hidden;
    }

    &:after {
      border: $arrow-width transparent solid;
      border-top-color: $brand-white;
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - #{$arrow-width});
      top: - calc($arrow-width / 2);
      width: $arrow-width;
      height: $arrow-height;
      overflow: hidden;
      z-index: 2;
    }
  }

  & &__body {
    background-color: $brand-white;

    &--padded {
      padding: 20px $padding-hr;
    }
  }
}
