.device-management-container {
  height: calc(100vh - 265px);
  overflow: scroll;
  position: relative;

  @media (max-width: 1920px) {
    max-height: none;
    overflow: auto;
  }

  .device-management__list-panel-item {
    border-bottom: 1px solid $gray-bg;
  }
}

.nsx-device-list {
  padding-left: 8px;
  padding-right: 8px;
}

.dev-mgm-multi-delete-buttons {
  z-index: 999;
  position: absolute;
  top: 30px;
  right: 25px;
  color: red;
}
