.nsx-dropdown {
  position: relative;
  display: inline-block;

  & &__body {
    $dropdown-offset: 5px;
    z-index: 1;
    position: absolute;
    overflow: hidden;
    left: auto;
    right: $dropdown-offset;
    top: calc(100% + #{$dropdown-offset});
    box-shadow: $box-stronger-shadow;
    background-color: $brand-white;
    border-radius: $nsx-base-border-radius;
    border: 0;
  }

  & &__menu {
    width: 200px;
  }
}
