.nsx-alert {
  $nsx-alert-width: 352px;

  position: fixed;
  top: 100px;
  left: 50%;
  width: $nsx-alert-width;
  margin-left: -#{calc($nsx-alert-width / 2)};
  padding: 12px;
  border-radius: $nsx-base-border-radius;
  box-shadow: 1px 1px 1px $gray-dark;
  z-index: 99970;

  @media screen and (max-height: 300px) {
    top: 10px;
  }

  &__title {
    font-size: $nsx-font-size-16;
    font-weight: 700;
    line-height: 24px;
  }

  &__row {
    display: flex;
  }

  &__main-col {
    flex-grow: 1;
  }
  &__left-col,
  &__right-col {
    flex-shrink: 0;
    flex-basis: 36px;
    text-align: center;
  }

  &__left-col {
    padding-right: 12px;
  }

  &__right-col {
    padding-left: 12px;
  }

  &__text {
    font-weight: 100;
    line-height: 1.5;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &__buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
  }

  &__close {
    color: $brand-white;

    &:hover {
      color: $gray-light-2-new;
    }
  }

  &__button {
    color: $brand-white;
    font-weight: bold;
    padding: 0 12px;
    min-width: 65px;
    text-transform: uppercase;
    text-align: center;

    &:hover {
      color: $gray-light-2-new;
    }
  }

  &__error {
    color: $brand-danger;
  }

  &__input {
    margin-bottom: 15px;
  }
}

.nsx-alert--style- {
  &danger { @extend %style-danger; }
  &primary { @extend %style-primary; }
  &warning { @extend %style-warning; }
  &dark-gray { @extend %style-dark-gray; }
}

.nsx-alert-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99950;
    overflow: hidden;
    outline: 0;
}

.nsx-alert__separator {
  width: 100%;
  height: 1px;
  background-color: $brand-white;
  margin-top: 2px;
  margin-bottom: 8px;
}

.nsx-alert__checkbox {
  position: relative;
  margin-left: 5px;
  margin-bottom: 18px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 25px;
  &.gpc {
    position: absolute;
    right: 20px;
  }
}

.nsx-alert__checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 15%;
}

.nsx-alert__checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.nsx-alert__checkbox input:checked ~ .checkmark {
  background-color: $brand-primary;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.nsx-alert__checkbox input:checked ~ .checkmark:after {
  display: block;
}

.nsx-alert__checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.gp-connect-tos-alert {
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  margin-left: 0;
}

.nsx-alert-name-not-allowed {
  $nsx-alert-width: $modal-alert-width;

  position: fixed;
  top: 100px;
  left: 50%;
  width: $nsx-alert-width;
  margin-left: -#{calc($nsx-alert-width / 2)};
  border-radius: $nsx-base-border-radius;
  border: solid $gray-dark 1px;
  z-index: 99970;

  @media screen and (max-height: 300px) {
    top: $grid-gutter-width;
  }
  height: $modal-alert-height;

  background: $brand-white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.nsx-alert-name-not-allowed__title {
  position: relative;
  width: 90%;
  height: 29px;
  margin: 12px 12px 24px 12px;

  font-family: 'FS Me';
  font-style: normal;
  font-weight: 700;
  font-size: $line-height-base-24px;
  line-height: 29px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: $gray-dark;
}

.nsx-alert-name-not-allowed__content {
  position: relative;
  width: 90%;
  height: 62px;
  margin: 0px 12px 0px 12px;

  font-family: 'FS Me';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: $primary-text-color;
}

.nsx-alert-name-not-allowed__close-btn-wrapper {
  position: relative;
  width: 90%;
  height: 60px;
  margin: 12px;
  display: table;
  float: right;
}
