.nsx-form {
  & &__actions {
    padding: 26px 0;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: center;
    gap: 20px;
  }
}
