.nsx-modal {}

.nsx-modal__close {
  position: absolute;
  right: -50px;
  top: 0;
}

.nsx-modal__header {
  padding: 30px;
  border-bottom: solid 1px $gray-bg;
  background-color: $brand-white;
}

.nsx-modal__title {
  font-size: 24px;
  font-weight: bold;
}

.nsx-modal__body {}

.nsx-modal__footer {}

.nsx-qr-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #182B34;
  color: white;
  padding: 20px;
}

.nsx-qr-modal__header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.nsx-qr-modal__logo {
  background-color: white;
  color: #182B34;
  border-radius: 50%;
  padding: 18px;
  margin-right: 20px;
}

.nsx-qr-modal__content {
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nsx-qr-modal__cancel-button {
  margin-right: 10px;
  background-color: white;
}

.nsx-qr-modal__exit-button {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  margin-left: auto;
}
.nsx-qr-modal__exit-button::before,
.nsx-qr-modal__exit-button::after {
  position: absolute;
  content: '';
  width: 2px;
  height: 18px;
  background-color: #182B34;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
.nsx-qr-modal__exit-button::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}