$need-review-time-width: 90px;
$need-review-actions-width: 90px;
$need-review-date-width: 150px;

.nsx-need-review {
  @extend .clearfix;
  border-bottom: solid 1px $brand-primary;
  line-height: 52px;
  margin-bottom: $baseline;
  padding: 0 calc($nsx-gutter / 2);
}

.nsx-need-review__avatar {
  float: left;
  width: $avatar-size-small;
  position: relative;
}

.nsx-need-review__photo {
  border: none;
  position: relative;
}

.nsx-need-review__avatar-label {
  position: absolute;
  top: 0;
  right: -5px;
}

.nsx-need-review__date {
  float: left;
  padding-right: $baseline;
  padding-left: $baseline;
  width: $need-review-date-width;
}

.nsx-need-review__date-time-icon {
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  bottom: -3px;
}

.nsx-need-review__time {
  float: left;
  padding-right: $baseline;
  width: $need-review-time-width;
}

.nsx-need-review__description {
  float: left;
  padding-right: $baseline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - #{(
    $need-review-time-width
    + $need-review-date-width
    + $need-review-actions-width
    + $avatar-size-small
  )});
}

.nsx-need-review__actions {
  float: right;
  width: $need-review-actions-width;
  text-align: right;

  .action-btn + .action-btn {
    margin-left: 0;
  }
}
