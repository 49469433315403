.nsx-list-panel-item {
  border-top: 1px solid $gray-bg;
  @extend .clearfix;

  &--even {
    background-color: $even-row-bg-color;
  }

  &__container {
    @extend .clearfix;
    padding: 10px 20px;
  }

  &__media {
    float: left;
    height: 60px;
    width: 60px;
  }
}
