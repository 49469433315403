$nsx-risk-spacer-width: 40px;

.nsx-need-risk {
  margin-bottom: $baseline;

  &:last-child {
    margin-bottom: 0;
  }
}

.nsx-risk-score {
  @extend .clearfix;
  margin-top: $baseline;

  &:first-child {
    margin-top: 0;
  }
}

.nsx-risk-score__item {
  width: 30%;
  float: left;
  width: calc((100% - #{$nsx-risk-spacer-width * 2}) / 3);

  @media screen and (max-width: 1300px) {
    width: 100%;
    clear: both;
    max-width: 220px;
  }
}

.nsx-risk-score__spacer {
  float: left;
  width: $nsx-risk-spacer-width;
  text-align: center;
  margin-top: $nsx-form-input-height;

  @media screen and (max-width: 1300px) {
    margin-top: $baseline;
    clear: both;
    text-align: left;
  }
}

.nsx-risk-score__entry {
  line-height: $nsx-form-input-height;
  border-bottom: $nsx-base-border;
}

.nsx-risk-score__title {
  font-size: $nsx-font-size-16;
}

.nsx-risk-score__title_missing {
  @extend .nsx-empty-input-text;
  font-size: 14px;
}

.nsx-risk-score__description {
  margin-left: calc($baseline / 2);
}
