.nsx-icon {
  $root: &;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-style: solid;
  border-width: 0;

  &--12 {
    $line-height: $nsx-icon-size-12  - ($nsx-icon-border-width * 2);
    width: $nsx-icon-size-12;
    height: $nsx-icon-size-12;
    line-height: $line-height;
    font-size: $nsx-icon-size-12;

    &#{$root}--padded {
      font-size: $nsx-icon-size-12 - ($nsx-icon-border-width * 2);
    }

    #{$all-icon-selectors} {
      line-height: $line-height;
    }
  }

  &--20 {
    width: $nsx-icon-size-20;
    height: $nsx-icon-size-20;
    line-height: $nsx-icon-size-20;
    font-size: $nsx-icon-size-20;

    &#{$root}--padded {
      font-size: $nsx-icon-size-20 - ($nsx-icon-border-width * 2);
    }

    #{$all-icon-selectors} {
      line-height: $nsx-icon-size-20;
    }
  }

  &--24 {
    width: $nsx-icon-size-24;
    height: $nsx-icon-size-24;
    line-height: $nsx-icon-size-24;
    font-size: $nsx-icon-size-24;

    &#{$root}--padded {
      font-size: $nsx-icon-size-24 - ($nsx-icon-border-width * 2);
    }

    #{$all-icon-selectors} {
      line-height: $nsx-icon-size-24;
    }
  }

  &--25 {
    width: $nsx-icon-size-25;
    height: $nsx-icon-size-25;
    line-height: $nsx-icon-size-25;
    font-size: $nsx-icon-size-25;

    &#{$root}--padded {
      font-size: $nsx-icon-size-25 - ($nsx-icon-border-width * 2);
    }

    #{$all-icon-selectors} {
      line-height: $nsx-icon-size-25;
    }
  }

  &--32 {
    width: $nsx-icon-size-32;
    height: $nsx-icon-size-32;
    line-height: $nsx-icon-size-32;
    font-size: $nsx-icon-size-32;

    &#{$root}--padded {
      font-size: $nsx-icon-size-32 - ($nsx-icon-border-width * 2);
    }

    #{$all-icon-selectors} {
      line-height: $nsx-icon-size-32;
    }
  }

  &--35 {
    width: $nsx-icon-size-35;
    height: $nsx-icon-size-35;
    line-height: $nsx-icon-size-35;
    font-size: $nsx-icon-size-35;

    &#{$root}--padded {
      font-size: $nsx-icon-size-35 - ($nsx-icon-border-width * 2);
    }

    &#{$root}--double-padded {
      font-size: $nsx-icon-size-35 - ($nsx-icon-border-width * 4);
    }

    #{$all-icon-selectors} {
      line-height: $nsx-icon-size-35;
    }
  }

  &--42 {
    width: $nsx-icon-size-42;
    height: $nsx-icon-size-42;
    line-height: $nsx-icon-size-42;
    font-size: $nsx-icon-size-42;

    &#{$root}--padded {
      font-size: $nsx-icon-size-42 - ($nsx-icon-border-width * 2);
    }


    #{$all-icon-selectors} {
      line-height: $nsx-icon-size-42;
    }
  }

  &--icon-size-32 {
    font-size: 32px;
  }

  &--sidebar {
    line-height: $sidebar-width-minimized;
  }

  //
  // other styles
  //

  &--round {
    border-radius: 50%;
  }

  &--bordered {
    border-width: 2px;
  }

  //
  // colours
  //
  &--primary,
  &--primary:focus {
      @extend %style-primary;
  }

  &--inactive,
  &--inactive:focus {
      @extend %style-inactive;
  }

  &--danger,
  &--danger:focus {
      @extend %style-danger;
  }

  &--danger-inverted,
  &--danger-inverted:focus {
      @extend %style-danger;
  }

  &--warning,
  &--warning:focus {
      @extend %style-warning;
  }

  &--success,
  &--success:focus {
      @extend %style-success;
  }

  &--success-inverted,
  &--success-inverted:focus {
      @extend %style-success-inverted;
  }

  &--black,
  &--black:focus {
      @extend %style-black;
  }

  &--dark-gray,
  &--dark-gray:focus {
      @extend %style-dark-gray;
  }
  &--black-inverted,
  &--black-inverted:focus {
      @extend %style-black-inverted;
  }

  &--inactive,
  &--inactive:focus {
    @extend %style-inactive;
  }

  &--inactive-inverted,
  &--inactive-inverted:focus {
    @extend %style-inactive-inverted;
  }

  &--round-black {
      @extend %style-black;
      border-radius: 100%;
  }

  &--fill-parent {
      height: 100%;
      width: 100%;
  }
}
