.nsx-table-axis-label {
  height: $nsx-table-axis-label-height;
  width: 100%;
  background-color: $brand-white;
  border-bottom: $nsx-base-border;
  color: $primary-text-color;
  padding: 10px;
  z-index: 5;
}

.nsx-table-axis-label--sidebar {
  position: absolute;
  left: 0;
  top: -$nsx-table-axis-label-height;
  border-left: $nsx-base-border;
  border-top: $nsx-base-border;
}

.nsx-table-axis-label__title {
  font-size: $nsx-font-size-20;
  font-weight: bold;
}

.nsx-table-axis-label__dropdown-link {
  color: $primary-text-color;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
