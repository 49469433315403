.nsx-new-flag {
  height: 17px;
  font-size: 10px;
  font-weight: $nsx-font-weight-bold;
  color: $brand-white;
  background-color: $brand-primary;
  padding: 3px 5px 0 5px;
  display: inline-block;
  position: relative;
  border-radius: 2px;
  margin-left: 10px;
  cursor: pointer;
}
