.nsx-onboarding-page-content {
  min-height: 320px;
}

.nsx-onboarding-page-content__heading {
  @extend %title-font-style;
  margin-bottom: $nsx-conf-row-margin;
}
.nsx-onboarding-page-content__confirmation-row {
  @extend %speech-text-font-style;
  @extend .clearfix;
  margin-bottom: $nsx-conf-row-margin;

  .checkbox {
    margin: 0;

    label {
      padding: 0;
    }
  }

  .checkbox-label {
    display: block !important;
    margin-top: 3px;
  }

  .help-block {
    color: $primary-text-color;
    font-size: 12px;
    font-weight: 100;
  }
}

.nsx-onboarding-page-content__pdf-icon {
  width: 92px;
  height:92px;
  display:block;
  margin: 50px auto 0;
}

.nsx-onboarding-page-content__confirmation-main-col {
  float: left;
  width: $nsx-conf-text-width;
}

.nsx-onboarding-page-content__confirmation-secondary-col {
  float: left;
  width: calc(100% - #{$nsx-conf-text-width});
}

.nsx-onboarding-page-content__text {
  @extend %speech-text-font-style;
  margin-bottom: $nsx-conf-row-margin;

  p {
    margin-bottom: $nsx-conf-row-margin;
  }
}

.nsx-onboarding-page-content__paragraph {
  font-size: $nsx-font-size-18;
  color: $gray-dark;
}
