.nsx-password-strength-text {
  margin-top: 7px;
  font-size: 12px;
}

.nsx-password-strength-score-text {
  float: right;
  font-weight: $nsx-font-weight-bold;

  &--danger {
    color: $brand-danger;
  }

  &--warning {
    color: $brand-warning;
  }

  &--success {
    color: $brand-success;
  }
}

.nsx-password-strength-bar {
  margin-top: 8px;
  background-color: $gray-medium-new;
  width: 100%;
  height: 8px;
  &__progress {
    height: 100%;

    &--0 {
      width: 25%;
    }
    &--1 {
      width: 25%;
    }
    &--2 {
      width: 50%;
    }
    &--3 {
      width: 75%;
    }
    &--4 {
      width: 100%;
    }
  }

  &__colour {
    &--danger {
      background-color: $brand-danger;
    }

    &--warning {
      background-color: $brand-warning;
    }

    &--success {
      background-color: $brand-success;
    }
  }
}

.nsx-password-requirements {
  padding-top: 5px;
  font-size: $font-size-small;
  line-height: 16px;

  .nsx-password-requirements-minimum{
    color: $brand-danger;
    font-weight: bold;

    &__colour {
      &--danger {
        color: $brand-danger;
        font-weight: bold;
      }

      &--warning {
        color: $brand-warning;
        font-weight: bold;
      }

      &--success {
        color: $brand-success;
        font-weight: bold;
      }
    }
  }

  a {
    color: $brand-black;
    text-decoration: underline;
  }
}

.nsx-password-suggestions {
  font-size: $font-size-small;
}

.nsx-show-password {
  $icon-width: 20px;
  width: $icon-width;
  height: $icon-width;
  position: absolute;
  right: 10px;
  top: calc($nsx-form-input-height / 2) - calc($icon-width / 2);

  .nsx-show-password-img {
    cursor: pointer;

    &.show-icon {
      content: url("#{$nsx-image-path}icons/show-password.svg");
    }

    &.hide-icon {
      content: url("#{$nsx-image-path}icons/hide-password.svg");
    }
  }
}
