$daterangepicker-size: 40px;

.date-picker-wrapper {
  background: white;
  border-radius: 4px;
  color: #172b34;
  font-family: inherit;
  font-size: 16px;
  max-height: 100%;
  line-height: 20px;
  max-width: 100%;
  z-index: 2;

  .handover-wrapper {
    max-height: 100%;
  }

  .month-name {
    text-transform: capitalize;
  }

  .month-element {
    display: inline-block;
  }

  .month-wrapper {
    border: 0;
    display: flex;
    justify-content: space-between;
    width: auto !important;

    > *:not(.month1):not(.month2) {
      display: none;
    }

    .month2, .month1 {
      width: auto !important;
    }

    .month1 {
      margin-right: 25px;
    }

    .month2 {
      margin-left: 25px;
    }

    .week-name th {
      height: $daterangepicker-size;
    }

    table .week-name {
      text-transform: capitalize;
    }

    table .day.real-today {
      background: $brand-primary;
    }

    table .day {
      align-items: center;
      display: flex;
      font-size: 16px;
      height: $daterangepicker-size;
      justify-content: center;
      line-height: 20px;
      margin-bottom: 6px;
      width: $daterangepicker-size;
    }

    table .day.checked,
    table .day.hovering.tmp.valid {
      background: rgba($brand-primary, .5);
      z-index: 0;
    }

    table .day.first-date-selected,
    table .day.last-date-selected {
      background: transparent !important;
      position: relative;
      z-index: 1;

      &:after {
        background: $brand-primary !important;
        border-radius: 50%;
        content: '';
        height: $daterangepicker-size + 15px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: $daterangepicker-size + 15px;
        z-index: -1;
      }

      &:before {
        background: rgba($brand-primary, .5);
        content: '';
        height: 100%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        z-index: -2;
      }
    }

    table .day.last-date-selected:before {
      left: 0;
    }
  }
}

.handover-datepicker-body {

  @media (max-width: 800px) {
    position: fixed !important;
    right: 470px !important;
    margin-right: 10px !important;
  }

  .date-picker-wrapper {
    bottom: 300px;
    left: 210px;
    margin-top: 40px;
    box-shadow: -2px 3px 8px 1px rgba(23,43,52,0.55);
  }

  // adds an arrow to the top of the date-picker-wrapper
  .date-picker-wrapper:after {
    content: "";
    display: block;
    position: absolute;
    border-style: solid;
    border-color: #bfbfbf;
    border-width: 1px 0 0 1px;
    width: 19px;
    height: 19px;
    top: 230px;
    left: -10px;
    background: inherit;

    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);

    @media (max-width: 800px) {
      left: 330px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
}

.date-picker-wrapper.single-date .day:before {
  display: none;
}
