.permissions-page-container {
  width: 100%;
  height: 100%;
  padding: #{$nsx-gutter + $navbar-height + $onboarding-nav-height} $nsx-gutter $nsx-gutter;
  overflow: hidden;
}

.permissions-update-all {
  position: relative;
}

.permissions-update-all__link {
  margin-right: 15px;
  margin-bottom: 10px;
  display: block;
  color: $primary-text-color;
  float: right;
}

.permission-input {
  width: 28.3%;
  margin: 0 2.5%;
  float: left;
  text-align: center;

  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }
}

.permission-input__checkbox {

  .nsx-checkbox-label {
    height: 22px;
    padding-left: 20px !important;
  }
}

.permission-input__label {
  display: block;
  font-size: $nsx-font-size-12;
  font-weight: 400;
}
