.nsx-list-panel-item-service {
  padding: 10px 28px;
  min-height: 60px;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  position: relative;
}

.nsx-list-panel-item-service__media {
  position: relative;
  align-self: flex-start;
  float: left;
  height: 60px;
  width: 60px;

  &--center {
    display: flex;
    align-items: center;
  }
}

.nsx-list-panel-item-service__text {
  float: left;
  padding-left: 24px;
  width: calc(100% - 421px)
}

.nsx-list-panel-item-service__text__primary {
  font-size: 20px;
  line-height: 24px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nsx-list-panel-item-service__text__secondary {
  font-size: 14px;
  line-height: 16px;
  color: $gray-dark;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nsx-list-panel-item-service__actions {
  text-align: right;
  width: 360px;
  align-self: center;
  color: $gray-dark;
}

.nsx-list-panel-item-service__icon {
  color: $gray-dark;
  font-size: 48px;
}

.nsx-list-panel-item-service__actions-action {
  display: inline-block;
  min-width: 30px;
  margin-left: 16px;
}

.nsx-list-panel-item-service__badge {
  position: absolute;
  width: 18px;
  height: 18px;
  top: -2px;
  right: -2px;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;

  &--success {
    @include style-success;
  }

  &--danger {
    @include style-danger;
  }
}

.nsx-list-panel-item-service-libraries {
  width: 8px;
  height: 90%;
  background: #66cccc;
  position: absolute;
  left: 0px;
  border-radius: 0 4px 4px 0;
}

.nsx-list-panel-item-service__a {
  color: $brand-black;
}
