// down the line give these more meaningful names
// when we have more templates
// e.g. .font-h1 or something

%title-font-style {
  font-size: $nsx-font-size-24;
  font-weight: bold;
}

%nsx-onboarding-nav-font-style {
  font-size: $nsx-font-size-18;
  text-transform: uppercase;
  font-weight: 700;
}

%speech-text-font-style {
  font-size: $nsx-font-size-18;
  font-weight: 700;
}


.nsx-text-link {
    font-size: $nsx-font-size-18;
    line-height: $nsx-form-input-height;
    color: $gray-dark;
}

.nsx-empty-input-text {
  color: $brand-danger;
}

.nsx-extensions-error {
  color: #fc0304;
}
