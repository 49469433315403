/*
 * Stuff like inputs are being overriden by overly specific old code
 * Neil has said all text buttons will be the same so this shouldn't cause any issues
*/

.nsx-btn {
  font-size: $nsx-font-size-18 !important;
}


/*
 * In the onboarding form, the datetimepicker was having 3d transform issues and appearing
 * behind input fields. I'm fixing this in here rather than globally incase adding 3d transforms
 * creates issues in other parts of the application
 *
 * It could be a Chrome bug as I didn't notice it until Chrome v59.
*/

.nsx-onboarding-section,
.simple-panel-onboarding {
  .bootstrap-datetimepicker-widget {
    transform: translate3d(0, 0, 0);
  }
}



/* Rotas table is using ".table", a CSS selector that is loaded with customizations
* that we need and others we don't. One of them is the padding added to table cells.
* It would be a huge amount of work to refactor the whole thing because it
* affects pretty much everything that uses ".table", so until then, we are
* overriding table cells padding by setting it to 0.
* Background-color is pretty much the same case.
*/
.nsx-rotas-cell {
  padding: 0 !important;
  background-color: white !important;
}

/*
 * .nsx-main-container is for the new angular only app.
 * When we eventually refactor .double-panel to .nsx-double-panel
 * there shouldn't be a margin on it so this won't be required
 */

.nsx-main-container {
  .double-panel {
    margin-top: 0;
  }
}

/*
 * Override form input bootstrap styles
 */

.nsx-form-group__input {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  input[type="text"],
  .select2-choices {
    border: $nsx-base-border;
    box-shadow: none;
  }
}

/*
 * Bootstrap adds very specific style rules such as input[type="text"] so
 * they can't be overriden by other less specific rules. In such case
 * !important is required
 */
.nsx-search-box__input {
  border: 0 !important;
  box-shadow: none !important;
  color: $primary-text-color !important;
  font-weight: 100 !important;
  font-size: $font-size-h4 !important;
  padding-right: 0 !important;
}


.nsx-params-entry-help-row {
  @extend .u-flex-center;
  min-height: 60px;

  &__icon-spacer {
    display: inline-flex;
    justify-content: center;
    flex: 0 0 75px;

    @media screen and (max-width: $screen-sm) {
      flex-basis: 55px;
    }
  }

  &__content {
    display: inline-flex;
    padding-right: 10px;
    font-size: $nsx-font-size-14;
  }

  &__action {
    display: inline-flex;
    flex-shrink: 0;
    margin-left: auto;
  }
}

/*
 * Override bootstrap datetimepicker smart position calculations
 */
.bootstrap-datetimepicker-widget-smart-position {
  top: auto !important;
  bottom: 0 !important;
}

.nsx-vue-container{
  width: calc(100% - 56px);
  @media screen and (max-width: $mobile-nav-breakpoint) {
    width: 100%;
  }
}
