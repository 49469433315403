.nsx-person-table-cell {
  display: flex;
  @include nsx-list-entry-builder(80px, 0px);
}

.nsx-person-table-cell__main-col {
  padding-top: 15px;
}

.nsx-person-table-cell__title {
  font-size: $nsx-font-size-20;
  color: $primary-text-color;
  margin: 0;
  line-height: 1.2;
  @extend %text-ellipsis;
}

.nsx-person-table-cell__subtitle {
  color: $secondary-text-color;
  font-size: $nsx-font-size-14;
  line-height: 1.2;
  @extend %text-ellipsis;
}
