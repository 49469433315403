@import './nsx_dashboard.scss';

.nsx-dashboard-header {
  align-items: flex-start;
  background-color: $gray-light-bg;
  box-shadow: $box-shadow-top-bar;
  color: $primary-text-color;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 $baseline;
  justify-content: space-around;

  @media (min-width: $dashboard-tablet-breakpoint) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.nsx-dashboard-header__title,
.nsx-dashboard-header__stats,
.nsx-dashboard-header__filters {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
}

.nsx-dashboard-header__title {
  align-items: center;
  color: $primary-text-color;
  display: flex;
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  max-width: 100%;
}

.nsx-dashboard-header__stats {
  display: flex;
  justify-content: center;

  @media (max-width: $dashboard-desktop-breakpoint) {
    display: none;
  }
}


.nsx-dashboard-header__stat:not(:first-child) {
  border-left: solid 1.4px #dad9d6;
}

.nsx-dashboard-header__filters {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $dashboard-tablet-breakpoint) {
    justify-content: flex-end;
  }
  @media (max-width: $dashboard-desktop-breakpoint) {
    display: none;
  }
}

.nsx-dashboard-header__timepicker {
  display: flex;
  justify-content: flex-end;
}
