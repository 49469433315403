.nsx-toggled-form {
  @extend .clearfix;
  background-color: $gray-primary-light;
  position: relative;
  padding: 0;

  .arrow-wrapper {
    position: absolute;
    display: block;
    height: 18px;
    overflow-y: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;

    &:after {
      @include arrow("bottom", 32px, white);
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - 32px);
      top: -16px;
      width: 32px;
      height: 18px;
      overflow: hidden;
      z-index: 2;
    }

    &:before {
      @include arrow("bottom", 33px, $gray-bg);
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - 32px - 1px);
      top: -16.5px;
      width: 32px;
      height: 18px;
      overflow: hidden;
      z-index: 1;
    }
  }

  &__section-heading {
    padding: 0 $nsx-gutter;
  }

  &__section-add-btn {
    text-align: right;
    position: absolute;
    right: -($sm-round-btn + $baseline*0.75);
    top: -5px;

    .double-panel-right & {
      top: 20px;
      right: 25px;
    }

    @media screen and (max-width: $screen-md) {
      top: 20px;
      right: 25px;
    }
  }

  &__inner {
    padding-left: $nsx-gutter;
    padding-right: $nsx-gutter;
    @extend .clearfix;
  }

  &__row {
    padding-top: $nsx-gutter;
    padding-bottom: $nsx-gutter;
    @extend .clearfix;

    .list-entry {
      &:first-child {
        border-top: 1px solid $gray-bg;
      }
    }
  }

  &__image-col {
    float: left;
    width: $nsx-image-col-width;

    .double-panel-right & {

      @media screen and (max-width: 1100px) {
        width: 100%;
      }
    }
    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }
  }

  &__main-col {
    float: left;
    padding-left: 5px;
    width: calc(100% - #{$nsx-image-col-width});

    .double-panel-right & {

      @media screen and (max-width: 1100px) {
        width: 100%;
      }
    }

    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }
  }

  .ns-checkbox-group {
    .checkbox {
      display: inline-block;
    }
  }

  .required {
    position: relative;

    &:after {
      content: "*";
      color: $brand-primary;
      position: absolute;
      font-size: 20px;
      right: 15px;
      top: 0px;

      @media screen and (max-width: 900px) {
        position: static;
      }
    }
  }

}
