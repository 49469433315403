// NAV
.nsx-onboarding-nav {
  position: fixed;
  left: $sidebar-width-minimized;
  top: $navbar-height;
  right: 0;
  z-index: 20;
  height: $onboarding-nav-height;
  background-color: $gray-primary-light;
  box-shadow: $nsx-onboarding-nav-box-shadow;

  .maintenance-banner-show & {
    top: $navbar-height + $maintenance-banner-height
  }
}

.nsx-onboarding-nav__link-blocker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.nsx-onboarding-nav__container {
  max-width: 1140px;
  padding: 0 $baseline;
  margin: 0 auto;
}

.nsx-onboarding-nav__main {
  float: left;
}

.nsx-onboarding-nav__secondary {
  float: right;
}

.nsx-onboarding-nav__padded-container {
  padding: 15px 0;
}

.nsx-onboarding-nav__item {
  @extend %nsx-onboarding-nav-font-style;
  line-height: $onboarding-nav-height;
  color: $primary-text-color;
  position: relative;
  padding: 0 5px;
  margin: 0 5px;
  display: inline-block;

  &.active,
  &:hover {
    text-decoration: none;
    color: $primary-text-color;
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background-color: $brand-primary;
    }
  }
}

@media screen and (max-width: $screen-sm-min) {
  .nsx-onboarding-nav {
    left: 0;
  }
}
