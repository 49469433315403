$dashboard-chart-loader-offset: 187;
$dashboard-chart-loader-duration: 1.6s;

.nsx-dashboard-chart-loader {
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
}

.nsx-dashboard-chart-loader__spinner {
  animation: dashboard-chart-loader-rotator $dashboard-chart-loader-duration linear infinite;
}

.nsx-dashboard-chart-loader__path {
  animation: dashboard-chart-loader-dash $dashboard-chart-loader-duration ease-in-out infinite;
  fill: none;
  stroke-dasharray: $dashboard-chart-loader-offset;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 6;
  stroke: $brand-primary;
  transform-origin: center;
}


@keyframes dashboard-chart-loader-rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes dashboard-chart-loader-dash {
  0% {
    stroke-dashoffset: $dashboard-chart-loader-offset;
  }

  50% {
    stroke-dashoffset: calc($dashboard-chart-loader-offset / 4);
    transform:rotate(135deg);
  }

  100% {
    stroke-dashoffset: $dashboard-chart-loader-offset;
    transform:rotate(450deg);
  }
}
