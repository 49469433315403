.nsx-interaction-list-item-quick-close {
  $quick-action-width: 180px;
  $transition: all .3s cubic-bezier(.4, 0, .2, 1);

  background-color: transparent;
  border-radius: 0 4px 4px 0;
  color: white;
  position: absolute;
  text-align: center;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
  overflow: hidden;
  z-index: 1;
  transition: $transition;

  &__inner {
    background-color: $brand-primary;
    position: relative;
    text-align: center;
    width: $quick-action-width;
    height: 100%;
    right: -33px;
    transition: $transition;

    &-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .icon {
      font-size: 20px;
      margin-bottom: 2px;
    }

    .icon, .text {
      display: block;
    }
  }

  &__interaction-wrapper {
    position: relative;
    left: 0;
    transition: $transition;
  }

  &:hover {
    width: $quick-action-width;
  }

  &:hover &__inner {
    background-color: $brand-success;
    right: 0;
  }

  &:hover + &__interaction-wrapper {
    left: -$quick-action-width;
    transition: $transition;
  }
}
