.nsx-form-group {
  @extend .clearfix;
  position: relative;
  margin-bottom: $nsx-gutter;
  padding: 0 13% 0 6%;

  @media screen and (max-width: $screen-sm) {
    padding: 0 6%;
  }

  @media print {
    margin-bottom: 0 !important;
  }

  .help-block,
  .alert-block {
    @extend .clearfix;
    clear: both;
    margin-top: 0;
    margin-bottom: 0;
    color: $primary-text-color;
    position: relative;

    span,
    .message {
      position: absolute;
      top: 2px;
      left: $nsx-control-label-width;

      @media screen and (max-width: 900px) {
        .double-panel-right & {
          left: 0;
        }
      }

      @media screen and (max-width: $screen-sm) {
        left: 0;
      }
    }
  }

  .help-block-link {
    color: $primary-text-color;
    text-decoration: underline;
  }

  &--flexible {
     margin-bottom: calc($nsx-gutter/2);
    .help-block,
    .alert-block {
      span {
        position: static;
        display: inline-block;
        padding-left: $nsx-control-label-width;

        @media screen and (max-width: 900px) {
          .double-panel-right & {
            padding-left: 0;
          }
        }

        @media screen and (max-width: $screen-sm) {
          padding-left: 0;
        }
      }
    }
  }

  .alert-block {
    color: $brand-danger;
  }

  &__read-only-output {
    color: $primary-text-color;
    font-size: $nsx-font-size-16;
    padding-top: 14px;
  }
}

.nsx-form-group--submit {
  margin-top: 20px;
  text-align: center;

  .nsx-btn {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin nsx-control-label-responsive {
  width: 100%;
  text-align: left;
  height: auto;

  span {
    position: static;
    padding-right: 0;
  }
}

.nsx-form-group__control-label {
  position: relative;
  font-weight: 700;
  font-size: $nsx-font-size-18;
  float: left;
  width: $nsx-control-label-width;
  text-align: right;
  padding-right: $nsx-gutter;
  height: $nsx-form-input-height;

  &-text {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0,-50%,0);
    width: 100%;
    padding-right: $nsx-gutter;
  }

  &-info {
    position: relative;
    top: 2px;
    margin-right: 6px;
  }


  @media screen and (max-width: 900px) {
    .double-panel-right & {
      @include nsx-control-label-responsive;
    }
  }
  @media screen and (max-width: $screen-sm) {
    @include nsx-control-label-responsive;
  }
}

.nsx-form-group__input {
  position: relative;
  width: calc(100% - #{$nsx-control-label-width});
  float: left;

  select,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  input[type="text"] {
    height: $nsx-form-input-height;
  }

  .select2-choices {
    min-height: $nsx-form-input-height;
  }

  textarea {
    min-height: $nsx-form-textarea-height;
  }

  select {
    @extend %nsx-select-style;
  }


  @media screen and (max-width: 900px) {
    .double-panel-right & {
    width: 100%;
    }
  }
  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}

.nsx-form-group__error-icon {
  $icon-width: 20px;
  width: $icon-width;
  height: $icon-width;
  background: $brand-danger;
  position: absolute;
  right: 10px;
  top: calc($nsx-form-input-height / 2) - calc($icon-width / 2);
}

.nsx-form-group__input-inner {
  @extend .clearfix;
}

.nsx-form-group__main-col {
  width: 54%;
  float: left;
  position: relative;
}

.nsx-form-group__secondary-col {
  width: 46%;
  float: left;
  padding-left: calc($nsx-gutter / 2);
}

.nsx-form-group__checkbox {
  margin-top: $nsx-form-small-gutter;
}

.nsx-form-group__additional-inputs {
  margin-top: $nsx-form-small-gutter;
}

.nsx-form-group__address {
  margin-top: 15px;
}

.nsx-form-group__edit-link {
  margin-top: 15px;
  display: block;
}

.password-link-color a {
  color: $brand-danger;
  text-decoration: underline;
}

.password-description-link-color a {
  color: $brand-black;
  text-decoration: underline;
}
