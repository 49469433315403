.nsx-dashboard-table {
  .nsx-dashboard-table__table.dataTable {
    $border: 2px solid $gray-primary-light;

    border: 0;

    th {
      background-color: $brand-primary;
      font-size: 20px;
      font-weight: normal;
    }

    td {
      font-size: 14px;
      line-height: 1.43;
    }

    th:not(:last-child), td:not(:last-child) {
      border-right: $border;
    }

    thead th, tbody td {
      border-bottom: $border;
      color: $brand-black;
      min-height: 36px;
      text-align: center;
    }
  }



  .dataTables_wrapper .dataTables_paginate {
    .paginate_button.current {
      color: $brand-primary !important;
    }

    .paginate_button:hover {
      border: 0 !important;
    }

    .paginate_button.current:not(.disabled):hover,
    .paginate_button:not(.disabled):hover {
      background: transparent;
      color: $brand-primary !important;
    }

    .paginate_button {
      background: transparent;
      border: 0;
    }
  }
}
