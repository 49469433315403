.nsx-menu-btn {
  position: relative;
  display: block;
  color: $brand-white;
  width: $sidebar-width-minimized;
  height: $sidebar-width-minimized;

  @media screen and (max-width: $mobile-nav-breakpoint) {
    position: fixed;
    top: 0;
    left: 0;

    .maintenance-banner-show & {
      top: $maintenance-banner-height;
    }
  }

  &:hover,
  &:focus {
    .nsx-menu-btn__line {
      background-color: $sidebar-link-hover-color;
    }
  }

  &__line {
    $line-width: 20px;
    $line-spacing: 5px;
    $line-height: 3px;

    position: absolute;
    transform-origin: center;
    width: $line-width;
    left: calc(50% - #{calc($line-width / 2)});
    background-color: $navbar-foreground;
    display: inline-block;
    height: $line-height;
    top: #{calc($sidebar-width-minimized / 2) - calc($line-height / 2)};
    transition: all 200ms linear;

    .nsx-menu-btn--is-active & {
      background-color: $brand-white;
    }

    &--middle {
      .nsx-menu-btn--is-active & {
        opacity: 0;
      }
    }

    &--top {
      top: #{calc($sidebar-width-minimized / 2) - calc($line-height / 2) - $line-spacing};

      .nsx-menu-btn--is-active & {
        top: #{calc($sidebar-width-minimized / 2) - calc($line-height / 2)};
        transform: rotate3d(0, 0, 1, 45deg);
      }
    }

    &--bottom {
      top: #{calc($sidebar-width-minimized / 2) - calc($line-height / 2) + $line-spacing};

      .nsx-menu-btn--is-active & {
        top: #{calc($sidebar-width-minimized / 2) - calc($line-height / 2)};
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}
