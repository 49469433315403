.nsx-interaction-search-result {
  $height: 68px;
  $padding-hr: 16px;

  $icon-size: 22px;
  $padding-icon-hr: $padding-hr;
  $padding-icon-vr: calc(($height - $icon-size) / 2);

  $right-column-width: 64px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: $height;
  width: 100%;
  padding: 0 $padding-hr;
  cursor: pointer;

  & &__body {
    flex-grow: 1;
    padding: 0 12px;
    overflow: hidden;
  }

  & &__right-column {
    flex-shrink: 0;
    flex-grow: 0;
    line-height: 1.5;
    width: $right-column-width;
    text-align: right;
  }

  & &__title {
    line-height: 1.1;
    font-size: $nsx-font-size-20;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & &__status {
    display: inline-block;
    line-height: 18px;
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
  }

  & &__description {
    font-size: $nsx-font-size-12;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
