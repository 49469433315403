$nsx-speech-intro-text-width: 540px;
$nsx-speech-intro-text-height: 210px;
$nsx-speech-intro-person-height: 226px;
$nsx-speech-intro-triangle-width: 50px;
$nsx-speech-intro-triangle-height: 40px;
$nsx-speech-intro-shadow-width: 10px;

.nsx-speech-intro {
  position: absolute;
  bottom: 0;
  top: $onboarding-nav-height + $navbar-height;
  left: 50%;
  width: $nsx-speech-intro-text-width;
  margin-left: -1 * calc($nsx-speech-intro-text-width / 2);
  z-index: 200;
}

.nsx-speech-intro--people-only {
  top: auto;
}

.nsx-speech-intro__text-wrapper {
  position: absolute;
  top: 40%;
  margin-top: -1 * calc($nsx-speech-intro-text-height / 2);
  background-color: $brand-white;
  width: 100%;
  min-height: $nsx-speech-intro-text-height;
  border-radius: 20px;
  box-shadow: -$nsx-speech-intro-shadow-width $nsx-speech-intro-shadow-width 0 $gray-primary;
  z-index: 1;
  padding: 18px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-right: 0;
    border-top: 0;
    width: $nsx-speech-intro-triangle-width;
    height: $nsx-speech-intro-triangle-height;
    border-left: solid $nsx-speech-intro-triangle-width $gray-primary;
    border-bottom: solid $nsx-speech-intro-triangle-height transparent;
    bottom: -$nsx-speech-intro-triangle-height - 7px;
    left: $nsx-speech-intro-triangle-height;
  }

  &:after {
    border-left: solid $nsx-speech-intro-triangle-width $brand-white;
    bottom: -$nsx-speech-intro-triangle-height;
    left: $nsx-speech-intro-triangle-height + $nsx-speech-intro-shadow-width;
  }

  @media screen and (max-height: 940px) {
    & {
      top: auto;
      margin-top: 0;
      bottom: 290px;
    }
  }

  @media screen and (max-height: 850px) {
    & {
      top: auto;
      bottom: 180px;
      margin-top: 0;
    }
  }
}

.nsx-speech-intro__text {
  @extend %speech-text-font-style;
  min-height: 124px;

  p {
    margin: 20px 0 0 0;

    &:first-child {
      margin: 0;
    }
  }
}

.nsx-speech-intro__back-button {
  float: left;
}

.nsx-speech-intro__next-button {
  float: right;
}

.nsx-speech-intro__bullets {
  line-height: 50px;
  text-align: center;
}

.nsx-speech-intro__bullet {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  display: inline-block;
  background-color: $gray-primary;

  &.active {
    background-color: $brand-black;
  }
}

.nsx-speech-intro__person {
  height: $nsx-speech-intro-person-height;
  width: auto;
  margin-top: 140px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;

  @media screen and (max-height: 850px) {
    & {
      height: calc($nsx-speech-intro-person-height / 2);
    }
  }
}

.nsx-speech-intro__person--damian {
  left: auto;
  right: -90px;

  @media screen and (min-height: 850px) {
    right: -200px;
  }
}

.nsx-speech-intro__person--dan {
  left: auto;
  right: 0;

  @media screen and (min-height: 850px) {
    height: 238px;
  }
}
