$nsx-asset-path: "../../assets/";
$nsx-font-path: "#{$nsx-asset-path}fonts/";
$nsx-image-path: "#{$nsx-asset-path}images/";

$nsx-font-size-12: 12px;
$nsx-font-size-14: 14px;
$nsx-font-size-16: 16px;
$nsx-font-size-18: 18px;
$nsx-font-size-20: 20px;
$nsx-font-size-24: 24px;
$nsx-font-weight-regular: 400;
$nsx-font-weight-light: 100;
$nsx-font-weight-bold: 700;

$nsx-base-border-radius: 4px;
$nsx-small-border-radius: 2px;
$nsx-border-color: $gray-border;
$nsx-base-border: 1px solid $nsx-border-color;
$nsx-base-line-height: 1.2;
$nsx-gutter: 30px;
$nsx-hover-darken: 2%;

$onboarding-nav-height: 80px;
$nsx-onboarding-nav-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.45);
$action-bar-height: 64px;

$nsx-interaction-icon-size: 44px;
$nsx-interaction-icon-padding: 4px;

$nsx-control-label-width: 160px;
$nsx-image-col-width: 160px;
$nsx-form-input-height: 50px;
$nsx-standard-field-width: 340px;
$nsx-form-textarea-height: 125px;
$nsx-form-small-gutter: 10px;

$nsx-conf-text-width: 260px;
$nsx-conf-row-margin: 75px;

$nsx-card-actions-col-width: 190px;
$nsx-card-left-col-width: 114px;

$nsx-icon-size-12: 12px;
$nsx-icon-size-20: 20px;
$nsx-icon-size-24: 24px;
$nsx-icon-size-25: 25px;
$nsx-icon-size-32: 32px;
$nsx-icon-size-35: 35px;
$nsx-icon-size-42: 42px;
$nsx-icon-size-60: 60px;
$nsx-icon-size-84: 84px;
// ideally icons would be scaled proportinally to their button
$nsx-icon-font-size-scale: 0.5;
$nsx-icon-border-width: 2px;

$nsx-table-axis-label-height: 44px;
$nsx-table-sidebar-width: 300px;
$nsx-table-cell-width: 200px;
$nsx-table-header-height: 110px;
$nsx-table-cell-height: 80px;
$nsx-editor-height: 150px;
$nsx-permission-column-width: 270px;

$isWebkit: '-webkit-min-device-pixel-ratio: 0';

%flex-wrapper-center {
  display: flex;
  align-items: center;
  width: 100%;
}
